import { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

import { get } from "./utils/functions";
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import Contact from "./pages/Contact";
import EmiCalculator from "./pages/EmiCalculator";
import Home from "./Home";
// import { ProductDetail } from './components/Productdetail';
// import { ProductDetail } from './components/ProductDetail/productDetail';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import SharedLayout from "./pages/admin/SharedLayout";

const Aboutus = lazy(() => import("./pages/Aboutus"));
const ProductDetails = lazy(() => import("./pages/ProductDetails"));
const Error = lazy(() => import("./pages/Error"));
const Estimate = lazy(() => import("./pages/Estimate"));
const Estimate2 = lazy(() => import("./pages/Estimate2"));
const Estimate3 = lazy(() => import("./pages/Estimate3"));
const NewExplore = lazy(() => import("./pages/NewExplore"));
const Finance = lazy(() => import("./pages/Finance"));
const Finance3 = lazy(() => import("./pages/Finance3"));
const FinanceCustomer = lazy(() => import("./pages/FinanceCustomer"));
const Finance2 = lazy(() => import("./pages/Finance2"));
const Review = lazy(() => import("./pages/Review"));
const CartPage = lazy(() => import("./pages/CartPage"));
const CartPurchased = lazy(() => import("./pages/CartPurchased"));
const Landing = lazy(() => import("./pages/Landing"));
const MyFinance = lazy(() => import("./pages/MyFinance"));
const MyListing = lazy(() => import("./pages/MyListing"));
const Sell = lazy(() => import("./pages/Sell"));
const SellBasic = lazy(() => import("./pages/SellBasic"));
const SellPage = lazy(() => import("./pages/SellPage"));
const Blog = lazy(() => import("./pages/Blog"));
const BlogDetailPage = lazy(() => import("./pages/BlogDetailPage"));
const Login = lazy(() => import("./pages/Login/Login"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage"));
const SellCalc = lazy(() => import("./pages/SellCalc"));
const TermAndConditionPage = lazy(() =>
  import("./pages/TermsAndConditionPage")
);
const Transfer = lazy(() => import("./pages/Transfer"));
const TruckDescription = lazy(() => import("./pages/TruckDescription"));
const NewTruckDescription = lazy(() => import("./pages/NewTruckDescription"));
const Upload = lazy(() => import("./pages/Upload"));
const VehicleRequirement = lazy(() => import("./pages/VehicleRequirement"));
const WAentry = lazy(() => import("./pages/WAentry/WAentry"));

function App() {
  const refresh = async (refresh_token) => {
    const token = localStorage.getItem("token");
    localStorage.removeItem("token");
    const resp = await get(
      "/user/renewtoken",
      { refresh_token },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (resp?.status_code === 200) {
      const { token, refreshToken } = resp.data;
      const date = new Date();
      const dateString = date.toISOString();
      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("token_date", dateString);
      const ref = localStorage.getItem("refresh_token");
      window.location.reload(true);
    }
  };

  if (localStorage.getItem("refresh_token")) {
    const currentdate = new Date();

    const tokendate = new Date(Date.parse(localStorage.getItem("token_date")));

    const timeDiff = (currentdate - tokendate) / 3600000;

    if (timeDiff <= 24) {
    } else {
      const ref = localStorage.getItem("refresh_token");
      refresh(ref);
    }
  }

  return (
    <div>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            // border: "1px solid #713200",
            padding: "16px",
            fontSize: "16px",
            minHeight: "40px",
            gap: "16px",
            // color: "#713200",
          },
        }}
      />
      <ToastContainer />

      <Suspense fallback={<div></div>}>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            {/* <Route path="/admin" element={<SharedLayout />} /> */}
            <Route path="/sell" element={<Sell />} />
            <Route path="/sell2" element={<SellCalc />} />
            <Route path="/sell1" element={<SellBasic />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/sell3" element={<Upload />} />
            <Route path="/sell4" element={<Review />} />
            <Route path="/verify" element={<Finance2 />} />
            <Route path="/finance2" element={<FinanceCustomer />} />
            <Route path="/finance3" element={<Finance3 />} />
            <Route path="/estimate" element={<Estimate />} />
            <Route path="/estimate2" element={<Estimate2 />} />
            <Route path="/estimate3" element={<Estimate3 />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermAndConditionPage />}
            />
            <Route path="/WAentry" element={<WAentry />} />
            <Route path="/transfer" element={<Transfer />} />
            <Route path="/aboutus" element={<Aboutus />} />
            {/* <Route path="/ProductDetails" element={<ProductDetails />} /> */}
            <Route path="/blogs" element={<Blog />} />
            <Route
              path="/blog-detail-page/:title/:id"
              element={<BlogDetailPage />}
            />
            {/* <Route
              path="/en/:type/:make/:model/:vehicle_uid"
              element={<TruckDescription />}
            /> */}
            {/* <Route
              path="/en/:type/:make/:model/:vehicle_uid"
              element={<NewTruckDescription />}
            /> */}
            <Route
              path="/en/:type/:make/:model/:vehicle_uid"
              element={<ProductDetails />}
            />
            {/* <Route path="/second-hand-truck-for-sale" element={<Explore />} /> */}
            <Route
              path="/second-hand-truck-for-sale"
              element={<NewExplore />}
            />
            <Route path="*" element={<Error />} />
            <Route path="/admin" element={<Login />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/cartpurchased" element={<CartPurchased />} />
            <Route path="/MyListing" element={<MyListing />} />
            <Route path="/MyFinance" element={<MyFinance />} />
            <Route
              path="/vehicle-requirements"
              element={<VehicleRequirement />}
            />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/sell-vehicle" element={<SellPage />} />
            <Route path="/emicalculator" element={<EmiCalculator />} />
            <Route path="/landing" element={<Home />} />

            {/* <Route path="/product-detail" element={<ProductDetail />} /> */}
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
