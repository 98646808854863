import React from "react";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import { ReactComponent as Contact } from "../../assets/CallIcon.svg";
import { ReactComponent as PersonalDetail } from "../../assets/personalDetail.svg";
import { ReactComponent as TruckSvg } from "../../assets/trucksvg.svg";
import { ReactComponent as BusSvg } from "../../assets/BusSvg.svg";
import { ReactComponent as RcTransfer } from "../../assets/RcTransfer.svg";

import { ReactComponent as FinanaceSvg } from "../../assets/finance-svgrepo-com.svg";
import "./SellPage.css";
import PersonalDetailSection from "./PersonalDetailsSection";
import VehicleNewDetailsSection from "./VehicleNewDetailsSection";
import RcDetails from "./RcDetails";
import SubmitSection from "./SubmitSection";
import SelectCategory from "./SelectCategory";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import qs from "qs";
import toast from "react-hot-toast";
import { root_url } from "../../utils/url.constant";
import { MdPhone } from "react-icons/md";
import Loader from "../Loader";

const SellPageComponent = () => {
  const navigationSection = [
    {
      icon: <PersonalDetail />,
      text: "Personal Details",
    },
    {
      icon: <TruckSvg />,
      text: "Vehicle Category",
    },

    {
      icon: <TruckSvg />,
      text: "Vehicle Details",
    },
    {
      icon: <RcTransfer />,
      text: "RC Details",
    },
  ];

  const [activeSection, setActiveSection] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [makeList, setMakeList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [bodyType, setBodyType] = useState([]);
  const [cargoLengthArr, setCargoLengthArr] = useState([]);
  const [fuelAlrr, setFuelArr] = useState([]);
  const [tyreConditionArr, setTyreConditionArr] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [tehsil, setTehsil] = useState([]);
  const [loading, setLoading] = useState(false);

  const [model, setModel] = useState([]);
  const [payload, setPayload] = useState([]);
  const [vehicleCategory, setvehicleCategory] = useState(null);

  const handleVehicleCategory = (vehicle) => {
    setvehicleCategory(vehicle);
    setData({ ...data, vehicleCategory: vehicle });
  };

  const [data, setData] = useState({
    vehicleCategory: "",

    fullName: "",
    mobileNumber: "",
    email: "",
    state: "",
    district: "",
    tehsil: "",

    make: "",
    model: "",
    purchasedYear: "",
    price: "",
    totalKm: "",
    RcNumber: "",
    vehicleWeight: 0,
    bodyType: 0,
    payload: 0,
    cargoLength: 0,
    tyreCondition: 0,
    fuel: 0,
    rcStatus: "yes",
    permitType: "",
    fitnessValidity: "",
    insuranceValidity: "",
    taxValidity: "",
  });

  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Sell Vehicle" }];

  useEffect(() => {
    axios
      .get(`${root_url}/truck/body-type`)
      .then((res) => {
        setBodyType(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${root_url}/truck/tyre-condition`)
      .then((res) => {
        setTyreConditionArr(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${root_url}/truck/cargo-length`)
      .then((res) => {
        setCargoLengthArr(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${root_url}/truck/fuel`)
      .then((res) => {
        setFuelArr(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${root_url}/truck/payload`)
      .then((res) => {
        setPayload(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${root_url}/location/states`)
      .then((res) => {
        setState(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (data?.state?.state_id) {
      axios
        .get(`${root_url}/location/districts/state/${data.state.state_id}`)
        .then((res) => {
          setDistrict(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (data?.vehicleCategory) {
      axios
        .get(`${root_url}/vehicle/brands?type=${data.vehicleCategory}`)
        .then((res) => {
          setMakeList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (data?.make?.brand_id) {
      const url = `${root_url}/vehicle/models/variants/brands/${data.make.brand_id}?type=${data.vehicleCategory}`;
      axios
        .get(url)
        .then((res) => {
          // Filter out duplicate model names
          setModel(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (data?.district) {
      axios
        .get(`${root_url}/location/tehsils/district/${data.district}`)
        .then((res) => {
          setTehsil(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data]);

  const activeSectionChangeHandler = (from) => {
    if (from === "back") {
      setActiveSection((prev) => prev - 1);
    } else {
      if (!handleDisbaled()) {
        if (activeSection === 2) {
          setLoading(true);
          const nonNullData = data?.images?.filter((val) => val != null);
          if (nonNullData?.length >= 2) {
            const [first, second, third, fourth] = nonNullData;

            const formData = new FormData();
            if (first) {
              formData.append("vei_first_img", first);
            }
            if (second) {
              formData.append("vei_second_img", second);
            }
            if (third) {
              formData.append("vei_third_img", third);
            }
            if (fourth) {
              formData.append("vei_fourth_img", fourth);
            }

            axios
              .post(
                `${root_url}/sell/new/listings/saveImg/:vehicle_uid`,
                formData
              )
              .then((res) => {
                setData({ ...data, vehicle_uid: res.data.data.vehicle_uid });
                setLoading(false);

                setActiveSection((prev) => prev + 1);
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
              });
          } else {
            setLoading(false);

            toast.error("Please fill all the detail");
          }
        } else {
          setLoading(false);

          setActiveSection((prev) => prev + 1);
        }
      } else {
        setLoading(false);
        toast.error("Please fill all the detail");
      }
    }
  };

  const appendSpreadsheet = async () => {
    if (handleDisbaled()) {
      toast.error("Please fill all the detail");
      return;
    }

    addNewListing();
  };

  const addNewListing = () => {
    const dataObj = {
      vehicle_uid: data.vehicle_uid,

      full_name: data?.fullName,
      mobile_number: data.mobileNumber,
      email: data.email,
      state: data?.state?.state_id,
      district: data.district,
      tehsil: data.tehsil,
      vehicle_no: data.RcNumber,

      make: data?.make?.brand_id,
      model: data?.model,
      purchased_year: data?.purchasedYear,
      fuel_id: data?.fuel,
      body_id: data?.bodyType,
      payload_id: data?.payload,
      cargo_length_id: data?.cargoLength,
      tyre_condition_id: data?.tyreCondition,
      price: data.price,
      vehicle_weight: data.vehicleWeight,
      total_km_driven: data.totalKm,
      rc_number: data.RcNumber,
      rc_status: data.rcStatus,
      permit: data.permitType,
      fitness_validity: data.fitnessValidity,
      insurance_validity: data.insuranceValidity,
      tax_validity: data.taxValidity,
    };

    axios
      .post(`${root_url}/sell/new/listings`, dataObj)
      .then((response) => {
        if (response.data?.status_code === 400) {
          toast.error(response?.data?.message);
        } else {
          setIsSubmitted(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChnageHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "make") {
      value = makeList.filter((item) => item.brand_id == value)[0];
    }

    if (name === "state") {
      value = state.filter((item) => item.state_id == value)[0];
    }

    setData({ ...data, [name]: value });
  };

  const handleDisbaled = () => {
    let disbaled = true;

    if (activeSection === 0) {
      disbaled = vehicleCategory;
    }

    if (activeSection === 1) {
      disbaled =
        data.fullName &&
        data.mobileNumber &&
        data.email &&
        data.state?.state_id &&
        data.district &&
        data.tehsil;
    }

    if (activeSection === 2) {
      disbaled = data.make && data.model;
    }

    if (activeSection === 3) {
    }
    return !disbaled;
  };

  return (
    <div className="wrapper">
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <div className="vehicle-req-container">
        <h2>Sell Your Vehicle in a Few Steps Only!</h2>
        <p>
          Experience a stress-free journey to sell your vehicle effortlessly
          with us.
        </p>
        <div className="sell-navigation-content-mobile">
          {navigationSection.map((item, index) => (
            <>
              <div
                className={`svg-text-box  ${
                  activeSection === index ? "isActive" : "isDisable"
                }`}
              >
                <div className="">{item.icon}</div>

                <h3 style={{ margin: "1px" }}>{item.text}</h3>
              </div>
              {index === 3 ? "" : <hr className="new1" />}{" "}
            </>
          ))}
        </div>

        <div className="vehicle-sell-form-container">
          <div className="form-navigation-container">
            {!isSubmitted && (
              <button
                onClick={() =>
                  activeSection > 0 && activeSectionChangeHandler("back")
                }
                className={` ${
                  activeSection === 0 ? "disbaled-btn" : "enabled-btn"
                }`}
              >
                {"<"} Back
              </button>
            )}

            <div className="form-navigation-content">
              {navigationSection.map((item, index) => (
                <>
                  <div
                    className={`svg-text-box  ${
                      activeSection === index ? "isActive" : "isDisable"
                    }`}
                  >
                    <div className="">{item.icon}</div>

                    <h3 style={{ margin: "1px", whiteSpace: "nowrap" }}>
                      {item.text}
                    </h3>
                  </div>
                  {index === 3 ? "" : <hr className="new1" />}{" "}
                </>
              ))}
            </div>

            {!isSubmitted ? (
              activeSection < 3 ? (
                <button
                  onClick={() => activeSectionChangeHandler("next")}
                  className={`${
                    handleDisbaled() ? "disbaled-btn" : "enabled-btn"
                  }`}
                >
                  Next {">"}{" "}
                </button>
              ) : (
                <button
                  onClick={appendSpreadsheet}
                  className={`${
                    handleDisbaled() ? "disbaled-btn" : "enabled-btn"
                  }`}
                >
                  Submit{" "}
                </button>
              )
            ) : null}
          </div>

          {!isSubmitted ? (
            loading ? (
              <div className="progress">
                <Loader />
              </div>
            ) : (
              <div>
                {activeSection === 1 && (
                  <PersonalDetailSection
                    data={data}
                    state={state}
                    district={district}
                    tehsil={tehsil}
                    onChnageHandler={onChnageHandler}
                  />
                )}
                {activeSection === 0 && (
                  <SelectCategory vehicleCategory={handleVehicleCategory} />
                )}
                {activeSection === 2 && (
                  <VehicleNewDetailsSection
                    bodyType={bodyType}
                    payload={payload}
                    model={model}
                    yearList={yearList}
                    make={makeList}
                    cargoList={cargoLengthArr}
                    fuelList={fuelAlrr}
                    tyreList={tyreConditionArr}
                    data={data}
                    onChnageHandler={onChnageHandler}
                  />
                )}
                {activeSection === 3 && (
                  <RcDetails data={data} onChnageHandler={onChnageHandler} />
                )}
              </div>
            )
          ) : (
            <div>{isSubmitted && <SubmitSection />}</div>
          )}
        </div>

        <div className="veh-section-2">
          <div className="content-container">
            <h3>GET IT FINANCED</h3>
            <p>
              Get behind the wheel of your dream <br /> truck with our{" "}
              <span style={{ color: "#F8B301", margin: "0px 10px" }}>
                {" "}
                hassle-free EMI options!{" "}
              </span>{" "}
            </p>
          </div>
          <div className="veh-btn-container">
            <button className="veh-know-more">
              <span>Know More </span>
            </button>
            <button className="veh-contact-us">
              <MdPhone /> <span>Contact Us</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellPageComponent;
