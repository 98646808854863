import React, { useEffect, useState } from "react";
import { createClient } from "contentful";
import moment from 'moment'
import { useParams, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
  InstapaperIcon,
  InstapaperShareButton,
} from "react-share";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import "./BlogDetailPageComponent.css";
import BlogCardComponent from "../BlogComponent/BlogCardComponent/BlogCardComponent";
import { ReactComponent as PrevArrow } from "../../assets/experimentArrowPrevious.svg";
import { ReactComponent as CopyIcon } from "../../assets/CopyLinkIcon.svg";
import Instagram from "../../assets/InstaIcon.svg";
import Image1 from "../../assets/Group.svg";
import Image2 from "../../assets/Vector.svg"
import { ReactComponent as Contact } from "../../assets/CallIcon.svg";
import BlogImage from "../../assets/blogImage.jpg";
import toast from "react-hot-toast";
import Loader from "../Loader";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const BlogDetailPageComponent = () => {
  const [singleBlogPost, setSingleBlog] = useState(null);
  const [list, setList] = useState([]);
  const [loadingDetail,setLoadingDetail] = useState(false)

  let { id,title } = useParams();

  const client = createClient({
    space: "n2g35udzh8t2",
    accessToken: "-aqPy5ucn5XxZ8HZEaMniVhDinNC9tIhpsa5uVbmqQ0",
  });

  useEffect(() => {
    const getEntryById = async () => {
      try {
        setLoadingDetail(true)
        await client.getEntries({
          'fields.urlSlug' : title,
          'content_type':'cavaloBlog'
        }).then((entries) => {
          setSingleBlog(entries?.items[0]);
          setLoadingDetail(false)
        });
      } catch (error) {
        console.log(`Error fetching authors ${error}`);
        setSingleBlog(null);
        setLoadingDetail(false)
      }
    };
    getEntryById();
  }, [id]);

  useEffect(() => {
    if (list.length === 0) {
      const getAllBlogs = async () => {
        try {
          await client.getEntries().then((entries) => {
            setList(entries);
          });
        } catch (error) {
          console.log(`Error fetching authors ${error}`);
          setList([]);
        }
      };
      getAllBlogs();
    }
  }, [client]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Blog", link: "/blogs" },
    { label: singleBlogPost?.fields?.blogTitle },
  ];


  if(loadingDetail){
   return <div className="progress">
    <Loader />
  </div>
  }



  return (
    <div className="wrapper">
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      {singleBlogPost ? (
        <>
          <div className="blog-basic-info">
            <h1 >
              {(() => {
                let text = singleBlogPost?.fields?.blogTitle?.split(" ");

                return (
                  <>
                    <span>{text?.[0] || ""}</span>{" "}
                    <span style={{ color: "#F8B301", margin: "0px 5px" }}>{`${
                      text?.[1] || ""
                    } ${text?.[2] || ""}`}</span>{" "}
                    <span>{text?.slice(3).join(" ")}</span>
                  </>
                );
              })()}
            </h1>

            <div

            className="date-tag-container"
            >
            <div className="date-part">
              <span style={{ fontSize: "16px", color: "#1E2130" }}>
                Published On:
              </span>
              <span
                style={{
                  fontSize: "16px",
                  color: "#929292",
                  marginLeft: "5px",
                }}
              >
                {moment(singleBlogPost?.fields?.blogDate).format("DD MMM YYYY")}
              </span>
              </div>
             {(singleBlogPost?.fields?.cavaloFieldTag || singleBlogPost?.fields?.cavaloIndustryTag) &&  <div className="tag-part">
                {singleBlogPost?.fields?.cavaloFieldTag && <span className="highlighted-tag">
                  {singleBlogPost?.fields?.cavaloFieldTag?.toUpperCase()}
                </span>}
              {singleBlogPost?.fields?.cavaloIndustryTag &&  <span className="non-highlighted-tag">
                  {singleBlogPost?.fields?.cavaloIndustryTag?.toUpperCase()}
                </span>}
              </div>}
            </div>
          </div>

          <div className="blog-data-container">
            <div className="blog-content">
              <img
                src={singleBlogPost?.fields?.blogImage?.fields?.file?.url}
                crossOrigin="anonymous"
                alt={singleBlogPost?.fields?.blogTitle}
              />
              <div className="blog-post-discription">
                <ReactMarkdown
                  children={singleBlogPost?.fields?.blogDescription}
                />
              </div>
              
              <div className="post-content">

                {documentToReactComponents(singleBlogPost?.fields?.blogContent
)}
              </div>

            </div>
            <div className="left-section">
              <div>
                <div className="share-component">
                  <span>Share With a Friend:</span>
                  <div className="">
                    <CopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        toast.success("Copied to clipboard");
                      }}
                      style={{
                        width: "32px",
                        height: "32px",
                        cursor: "pointer",
                      }}
                    />
                    <FacebookShareButton
                      className="mx-1 focus:outline-none"
                      url={window.location.href}
                      hashtag={"#cavalo"}
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <Link
                      className="option-btn"
                      to={
                        "https://instagram.com/cavaloofficial?igshid=YmMyMTA2M2Y="
                      }
                    >
                      <img
                        src={Instagram}
                        className="imageIcons me-2 ms-2"
                        alt=""
                      ></img>
                    </Link>
                    <WhatsappShareButton
                      className="mx-1 focus:outline-none"
                      url={window.location.href}
                      title={singleBlogPost?.fields?.blogTitle}
                    >
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>

              <div >
                <div className="financed-section">
                 <h3>GET IT FINANCED</h3>

                 <p>Get behind the wheel of your dream truck with our  <span style={{ color: "#F8B301", margin: "0px 5px" }}>hassle-free EMI options!</span> </p>
                  <div className="icon-div"><img src={Image1} className="" style={{marginRight:"5px"}}/> <img src={Image2} className=""/> </div>

                  <div className=" btn-alignment">
                 
                  <button className="contact-us">
                    <Contact /> <span>Contact Us</span>
                  </button>
                </div>
                </div>
              </div>


              <div >
              <div className="cavalo-blog-section">
              <div className="section-1">
               <h3>CAVALO BLOGS</h3>
               <div>EDITOR'S PICK</div>
               </div>

               <p> <span style={{ color: "#F8B301"}}> Best practices </span> to know when selling your truck!  </p>
                <div className="btn-alignment">
                <Link to="/blogs" className="cavalo-blog-know-more">
                 Know More {">"}
                </Link>
              </div>
              </div>
            </div>

            </div>
          </div>
        </>
      ) : <div style={{display:'flex',width:"100%",alignItem:'center',justifyContent:'center',fontSize:"22px",backgroundColor:"#1E2130",padding:"15px",color:"white"}}> No Data </div>}

      {(singleBlogPost?.fields?.previousBlog ||
        singleBlogPost?.fields?.upcomingBlog) && (
        <div className="navigation-container" style={{display:'flex',margin:"40px auto"}}>
          {singleBlogPost?.fields?.previousBlog && (
            <Link
              to={`/blog-detail-page/${singleBlogPost?.fields?.previousBlog?.fields?.urlSlug}/${singleBlogPost?.fields?.previousBlog?.sys?.id}`}
              className="prev-navigate"
            >
              <div className="prev-arrow-circle">
                <PrevArrow />
              </div>
              <div className="navigation-text">
                <p>Previous article</p>
                <h3>
                  {singleBlogPost?.fields?.previousBlog?.fields?.blogTitle.slice(
                    0,
                    30
                  )}
                  ...
                </h3>
              </div>
            </Link>
          )}

          {singleBlogPost?.fields?.upcomingBlog && (
            <Link
              to={`/blog-detail-page/${singleBlogPost?.fields?.upcomingBlog?.fields?.urlSlug}/${singleBlogPost?.fields?.upcomingBlog?.sys?.id}`}
              className="next-navigate"
            >
              <div className="next-arrow-circle">
                <PrevArrow />
              </div>
              <div className="navigation-text">
                <p>Next article</p>
                <h3>
                  {singleBlogPost?.fields?.upcomingBlog?.fields?.blogTitle.slice(
                    0,
                    30
                  )}
                  ...
                </h3>
              </div>
            </Link>
          )}
        </div>
      )}

      <hr />

      <div>
        <div className="related-article-blogs-section">
          <h3 >Related Articles</h3>

          <p>View All</p>
        </div>

        <div className="related-articles-container">
          {list?.items?.slice(0, 3).map((item, index) => (
            <BlogCardComponent post={item} key={item.sys.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetailPageComponent;
