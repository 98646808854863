import { useNavigate, createSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { get } from "../utils/functions";
import ExploreTruckImg from "../assets/exploreTruckImg.png";
import { root_url } from "../utils/url.constant";
const Wrapper = styled.div`
  width: fit-content;
  .explore-truck-item-page {
    display: flex;
    padding: 18px 18px 14px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #fff;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.04);
    max-width: 304px;
    box-sizing: border-box;

    /* padding-bottom:16rem; */
  }

  .explore-truck-item-page:hover {
    background-color: #fbfbfb;
    transform: scale(1.005);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    /* padding-bottom:16rem; */
  }

  .explore-truck-img-page {
    position: relative;

    overflow: hidden;

    /* padding: 10px; */
  }
  .explore-truck-img-page img {
    width: 268px;
    height: 211px;
    border-radius: 10px;
    object-fit: cover;
    z-index: -1;
  }

  .explore-truck-img-page div {
    position: absolute;
    /* left: 2vw; */
    width: 100%;
    margin: 0 !important;
    bottom: 12px;
    left: 12px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
  }
  .explore-truck-img-page span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0rem;
    display: flex;
    padding: 5px 10px;
    /* width: 83.07px;
    height: 27.03px; */
    border-radius: 4.01084px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10.0271px);
    margin: 0 !important;

    letter-spacing: 1.1;
    color: #ffffff;
    color: #fff;
    font-size: 12px;
    font-family: Mulish;
    font-weight: 500;
    letter-spacing: 0.24px;
    text-transform: uppercase;
  }

  .explore-truck-description-page {
    /* padding: 1rem; */
    box-sizing: border-box;
    width: 266px;
    padding: 0 10px 5px 10px;
    /* height: 132px; */
    /* margin-bottom:1.5rem; */
    /* text-align: center; */
  }

  .explore-truck-description-page h3 {
    color: #101010;
    font-size: 20px;
    font-family: Mulish;
    font-weight: 600;
    letter-spacing: 0.4px;
    /* margin: 0 1rem; */
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .explore-truck-details-page {
    color: #1e2130;
    font-size: 12px;
    font-family: Mulish;
    font-weight: 200;
    letter-spacing: 0.24px;
    margin-top: 6px;
  }

  .explore-truck-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    gap: 20px;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 4px;
    /* width */
  }
  .explore-truck-price span:first-child {
    /* background: #f6af33; */
    font-size: 28px;
    font-family: Mulish;
    font-weight: 800;
    letter-spacing: 0.56px;
    color: #1e2130;
    margin: 0;
  }

  .explore-truck-price span:last-child {
    color: #7d808e;
    font-size: 16px;
    font-family: Mulish;
    letter-spacing: 0.32px;
    text-decoration-line: strikethrough;
    margin: 0;
  }

  .inclusive {
    text-align: left;
    margin: 0;
    color: #7d808e;
    font-size: 12px;
    font-family: Mulish;
    letter-spacing: 0.24px;
    margin-top: 4px;
  }

  .explore-truck-know-more {
    width: 100%;
    margin-top: 2rem;
    box-sizing: border-box;
    background-color: #151720;
    border: 1px solid #151720;
    border-radius: 0.8rem;
    color: #ffffff;
    padding: 2rem 1rem;
    font-size: 1.8rem;
  }
  .explore-truck-know-more:hover {
    background-color: #151735;
    transform: scale(1.1);
    transition: all 0.1s ease-in 0.1s ease-out;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 767px) {
    &.item-wrapper {
      width: 100%;
      margin: 2rem 2rem;
    }
    /* .explore-truck-item-page {
      padding: 1.5rem 1.5rem 1rem 1.5rem;
      width: 100%;
    } */

    .explore-truck-img-page div {
      /* left: 2rem;
      bottom: 2rem; */
    }
    .explore-truck-img-page span {
      padding: 0.8rem 2rem;
      /* margin-right: 1rem; */
      letter-spacing: 1.1;
      color: #ffffff;
      /* font-size: 1.7rem; */
    }

    .explore-truck-description-page {
      padding: 0.5rem;
      box-sizing: border-box;
      /* text-align: center; */
    }

    /* .explore-truck-description-page h3 {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;

      letter-spacing: 0.02em;


      color: #101010;
    } */

    .explore-truck-details-page {
      color: #1e2130;
      font-size: 12px;
      font-family: Mulish;
      font-weight: 500;
      letter-spacing: 0.24px;
      /* margin-top: 10px; */
    }

    .explore-truck-price span:first-child {
      font-size: 2.5rem;
    }

    .explore-truck-price span:last-child {
      font-size: 2rem;
    }

    .inclusive {
      font-size: 140%;
    }

    .explore-truck-know-more {
      font-size: 1.3rem;
      width: 100%;
      padding: 1rem 0;
    }
  }
  @media (max-width: 550px) {
    .explore-truck-item-page {
      display: flex;
      width: 339px;
      /* height: 419px; */
      padding: 18.049px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 12.216px;
      /* box-sizing: border-box; */
    }
    /* .explore-truck-img-page img {
      width: 302px;
      height: 223.604px;
    } */

    .explore-truck-description-page {
      /* padding: 1rem; */
      box-sizing: border-box;
      width: 302px;
      /* height: 132px; */
      /* margin-bottom:1.5rem; */
      /* text-align: center; */
    }

    .explore-truck-description-page h3 {
      width: 87%
    }

    .explore-truck-details-page {
      color: #1e2130;
      font-size: 12px;
      font-family: Mulish;
      font-weight: 200;
      letter-spacing: 0.24px;
    }

    .explore-truck-price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: fit-content;
      gap: 20px;
      margin: 0;
      margin-top: 10px;
      /* width */
    }
    .explore-truck-price span:first-child {
      /* background: #f6af33; */
      font-size: 32.087px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.642px;
      color: #1e2130;
      margin: 0;
    }

    .explore-truck-price span:last-child {
      color: #7d808e;
      font-size: 18.049px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.361px;
      text-decoration-line: strikethrough;
      margin: 0;
    }

    .inclusive {
      text-align: left;
      margin: 0;
      color: #7d808e;
      font-size: 12px;
      font-family: Mulish;
      letter-spacing: 0.24px;
    }

    .explore-truck-know-more {
      width: 100%;
      margin-top: 2rem;
      box-sizing: border-box;
      background-color: #151720;
      border: 1px solid #151720;
      border-radius: 0.8rem;
      color: #ffffff;
      padding: 2rem 1rem;
      font-size: 1.8rem;
    }
  }
`;

const ExploreTruckItem = ({ data }) => {
  const navigate = useNavigate();

  console.log(data)

  return (
    <Wrapper
      onClick={() => {
        const searchParams = { vehicle_uid: data?.vehicle_uid };
        // searchParams.set("makename", e.target.value);

        navigate({
          pathname: `/en/${data?.vehicle_type === "N/A" ? "used" : data?.vehicle_type?.toLowerCase().replace(/ /g, "-")}/${data?.make.toLowerCase().replace(/ /g, "-")}/${data?.year}-${data?.model.toLowerCase().replace(/ /g, "-")}/${data?.vehicle_uid}`,
          // search: `?${createSearchParams(searchParams)}`,
        });

        // navigate("/productdescription", { state: data?.vehicle_uid });
      }}
    >
      {data && (
        <div className="explore-truck-item-page">
          <div className="explore-truck-img-page">
            <img
              crossOrigin="anonymous"
              src={`${root_url}/${data?.image}`}
              alt="explore truck "
            />
            <div className="span-container">
              <span>
                {data?.vehicle_type}
              </span>
              <span>
                {data?.fuel}
              </span>
            </div>
          </div>
          <div className="explore-truck-description-page">
            <h3>
              {data?.year} {data?.model} {data?.make}
            </h3>
            <div className="explore-truck-details-page">
              {data?.odometer_reading} Km |{data?.weight} | {data?.vehicle_type}{" "}
              | {data?.tyre_condition}{" "}
            </div>
            <div className="explore-truck-price">
              <span>
                {/* ₹ */}
                {data?.price?.toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                  style: "currency",
                  currency: "INR",
                })}
              </span>
              <span>
                {(data?.price + 20000)?.toLocaleString("en-IN", {
                  maximumFractionDigits: 0,
                  style: "currency",
                  currency: "INR",
                })}
              </span>
            </div>

            <span className="inclusive">inclusive of all taxes</span>

          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default ExploreTruckItem;
