import React, { useState } from 'react';
import './SelectCategory.css';
import Truck from "./CategoryIcons/TruckIcon";
import Pickup from "./CategoryIcons/Pickup";
import MiniTruck from "./CategoryIcons/MiniTruck";
import Tippers from "./CategoryIcons/Tippers";
import Trailer from "./CategoryIcons/Trailers";
import ThreeWheeler from "./CategoryIcons/ThreeWheeler";
import Auto from "./CategoryIcons/Auto";
import Transit from "./CategoryIcons/Transit";
import Erickshaw from "./CategoryIcons/Erickshaw";
import Bus from "./CategoryIcons/Bus";
import Van from "./CategoryIcons/Van";
import mixer from '../../assets/mixer.webp'
import active_mixer from '../../assets/active_mixer.webp'


const SelectCategory = ({ vehicleCategory }) => {
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const handleVehicleSelect = (vehicle) => {
        setSelectedVehicle(vehicle);
        vehicleCategory(vehicle);
    };

    return (
        <div className="personal-detail-main-box">
            <h2 className="main-heading">Select Your Vehicle Category</h2>

            <div className='select-vehicle-type'>
                {/* Truck */}
                <div className={selectedVehicle === 'Truck' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('Truck')}>
                    <Truck fill={selectedVehicle === 'Truck' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === 'Truck' ? 'text_active' : 'svg-text'}>Truck</div>
                </div>

                {/* Bus */}
                <div className={`vehicle-box`} onClick={() => handleVehicleSelect('Bus')}>
                    <Bus fill={selectedVehicle === 'Bus' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className='svg-text'>Bus</div>
                </div>

                {/* Pickup */}
                <div className={selectedVehicle === 'Pickup' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('Pickup')}>
                    <Pickup fill={selectedVehicle === 'Pickup' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === 'Pickup' ? 'text_active' : 'svg-text'}>Pickup</div>
                </div>

                {/* Mini Truck */}
                <div className={selectedVehicle === 'Mini Truck' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('Mini Truck')}>
                    <MiniTruck fill={selectedVehicle === 'Mini Truck' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === 'Mini Truck' ? 'text_active' : 'svg-text'}>Mini Truck</div>
                </div>

                {/* Tippers */}
                <div className={selectedVehicle === 'Tipper' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('Tipper')}>
                    <Tippers fill={selectedVehicle === 'Tipper' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === 'Tipper' ? 'text_active' : 'svg-text'}>Tippers</div>
                </div>

                {/* Trailer */}
                <div className={selectedVehicle === 'Trailer' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('Trailer')}>
                    <Trailer fill={selectedVehicle === 'Trailer' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === 'Trailer' ? 'text_active' : 'svg-text'}>Trailer</div>
                </div>

                {/* Three Wheelers */}
                <div className={selectedVehicle === '3 Wheeler' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('3 Wheeler')}>
                    <ThreeWheeler fill={selectedVehicle === '3 Wheeler' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === '3 Wheeler' ? 'text_active' : 'svg-text'}>3 Wheelers</div>
                </div>

                {/* Auto Rickshaw */}
                <div className={selectedVehicle === 'Auto Rickshaw' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('Auto Rickshaw')}>
                    <Auto fill={selectedVehicle === 'Auto Rickshaw' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === 'Auto Rickshaw' ? 'text_active' : 'svg-text'}>Auto Rickshaw</div>
                </div>

                {/* Traveller */}
                <div className={selectedVehicle === 'Traveller' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('Traveller')}>
                    <Transit fill={selectedVehicle === 'Traveller' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === 'Traveller' ? 'text_active' : 'svg-text'}>Tempo Traveller</div>
                </div>

                {/* Transit Mixer */}
                <div className={selectedVehicle === 'Transit Mixer' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('Transit Mixer')}>
                    {/* <Transit fill={selectedVehicle === 'Transit Mixer' ? '#F8B301' : '#888888'} className='svg-icon' /> */}

                    {
                        selectedVehicle === 'Transit Mixer'
                            ?
                            <img src={active_mixer} alt='' className='svg-icon' />
                            :
                            <img src={mixer} alt='' className='svg-icon' />
                    }
                    <div className={selectedVehicle === 'Transit Mixer' ? 'text_active' : 'svg-text'}>Transit Mixer</div>
                </div>

                {/* E Rickshaw */}
                <div className={selectedVehicle === 'E Rickshaw' ? 'vehicle_active' : 'vehicle-box'} onClick={() => handleVehicleSelect('E Rickshaw')}>
                    <Erickshaw fill={selectedVehicle === 'E Rickshaw' ? '#F8B301' : '#888888'} className='svg-icon' />
                    <div className={selectedVehicle === 'E Rickshaw' ? 'text_active' : 'svg-text'}>E Rickshaw</div>
                </div>


            </div>
        </div>
    );
};

export default SelectCategory;
