import styled from "styled-components";

const Wrapper = styled.main`
  width: 100%;
  overflow: hidden;
  scroll-snap-type: y mandatory;

  /* body {
    overflow-x: hidden;
    scrollbar-color: rgb(98 84 243);
    scrollbar-width: thin;
  } */
  .mid_section {
    display: flex;
    width: 6.944vw;
  }
  .card_sections {
    padding: 5vh 2.5vw;
    margin-bottom: 15vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
  }
  .services-section {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1e2130;
    height: 626px;
    gap: 64px;
  }

  .services-section h3 {
    font-size: 4rem;
    text-align: center;
    color: #ffffff;
    margin-top: 94px;
    margin-bottom: 0;
  }

  .services-section h3 span {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .services-items {
    width: 1063px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* margin-bottom: 10rem;
     */
    grid-gap: 4%;
  }

  .buyguide-section {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin: 0 auto;
  }
  .buyguide-heading {
    width: 100%;
    font-size: 3.8rem;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
  }

  .buyguide-heading div {
    margin-top: 72px;
    width: 65%;
  }
  .buyguide-heading div h2 {
    font-size: 48px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.24px;
    margin: 0;
    margin-bottom: 45px;
  }
  /* .buyguide-heading div img {
    width: 100%;
  } */

  .buyguide-img img {
    right: 0;
    width: 100%;
    /* height: 676px; */
    z-index: -1;
  }

  .explore-truck-sections {
    background-image: url(${require("../assets/trucksExplorebg.png")});
    padding: 65px 0 65px ;
    box-sizing: border-box;
    min-height: 743px;
    position:relative;
  }

  .explore-truck-inner {
    width: 1063px;
    margin: 0 auto;
  }
  .explore-truck-inner-prod {
    width: fit-content;
    margin: 0 auto;
  }

  .explore-truck-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .explore-truck-heading h3 {
    font-size: 42px;
    font-family: Mulish;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.21px;
    color: #ffffff;
    margin: 0;
  }

  .explore-truck-heading button {
    width: 10rem;
    height: 4rem;
    color: #ffffff;
    background-color: #1e2130;
    border: 1px solid #1e2130;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .explore-truck-heading button:hover {
    background-color: #1e2140;
    transform: scale(1.3);
    transition: all 0.1s;
  }

  .explore-truck-heading button img {
    margin-left: 1rem;
    width: 0.7rem;
  }

  .explore-truck-heading h3 span {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .explore-truck-items {
    display: flex;
    width: 1062px;
    justify-content: space-between;
    align-items: flex-start;
    /* overflow-x: scroll; */
    width: 1063px;
  }

  
  .explore-truck-items-prod {
    display: flex;
    width: 1062px;
    justify-content: space-between;
    align-items: flex-start;
    /* overflow-x: scroll; */
    width: 1280px;
  }

  .trucks-by-brand-section {
    margin: 10rem 0;
  }

  .trucks-by-brand-inner {
    width: 80%;
    margin: 0 auto;
  }

  .trucks-by-brand-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
  }

  .trucks-by-brand-heading h3 {
    font-size: 42px;
    font-family: Mulish;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.21px;
    color: #1e2130;
    margin: 0;
  }

  .trucks-by-brand-heading button {
    /* width: 10rem; */
    /* height: 4rem; */
    color: #1e2130;
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 24px;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 700;
  }

  .trucks-by-brand-heading button:hover {
    background-color: #f3f3f3;
    transform: scale(1.1);
    transition: all 0.1s;
  }

  .trucks-by-brand-heading button img {
    margin-left: 1rem;
    width: 20px;
    height: 20px;
  }

  .trucks-by-brand-heading h3 span {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .truck-brand-items {
    display: flex;
    /* border:1px solid red; */
    /* flex-wrap: wrap; */
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    margin: 24px;
  }

  .sell-oldtruck-section {
    margin: 73px auto 84px auto;
  }
  .sell-oldtruck-section h2 {
    margin: 0;
    margin-bottom: 37px;
    text-align: center;
    font-size: 42px;
    font-family: Mulish;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.21px;
  }

  .cavalo-deals-blog {
    width: 60%;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1.3fr 1fr;
    margin: 0 auto;
  }

  .get-estimate {
    position: relative;
    width: 100%;
    height: 40rem;
    text-align: left;
    background-color: #1e2130;
    border-radius: 1.5rem;
    padding: 3rem;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }

  .get-estimate h6 {
    color: #fdebb0;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2em;
    margin: 2rem 0;
    text-transform: capitalize;
  }

  .get-estimate h3 {
    color: #ffffff;
    font-size: 4rem;
    margin: 0;
  }

  .get-estimate h3 span {
    color: #ffffff;
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .get-estimate button {
    padding: 2rem 1rem;
    width: 20rem;
    background-color: #f8b301;
    border: 1px solid #f8b301;
    border-radius: 0.8rem;
    position: absolute;
    bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.9rem;
    font-weight: 500;
    color: #1e2130 !important;
  }

  .get-estimate button img {
    margin-right: 1rem;
  }

  .get-estimate-img {
    position: absolute;
    bottom: -2px;
    right: 20px;
    width: 50%;
    /* height: 72.869px; */
  }
  .get-estimate-arrow {
    position: absolute;
    top: 55%;
    left: 25%;
    height: 75px;
    transform: rotate(350deg);
  }
  .oldtruck-blogs {
    border: 2px solid #d4d4d4;
    border-radius: 0.8rem;
    padding: 3rem;
    position: relative;
    box-sizing: border-box;
  }
  .oldtruck-blogs .get-estimate-img {
    display: none;
  }
  .oldtruck-blogs h6 {
    color: #1e2130;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2em;
    margin: 0;
  }

  .oldtruck-blogs h3 {
    color: #1e2130;
    font-weight: 800;
    font-size: 42px;
    line-height: 120%;
    font-family: "Mulish";
    margin: 0;
    margin-bottom: 2rem;
  }

  .oldtruck-blogs button {
    padding: 2rem 1rem;
    width: 20rem;
    color: #ffffff;
    background-color: #1e2130;
    border: 1px solid #1e2130;
    border-radius: 0.8rem;
    position: absolute;
    bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.9rem;
    font-weight: 500;
  }

  .oldtruck-blogs button img {
    margin-left: 1rem;
  }

  .happy-customers-section {
    /* background-color: #1e2130; */
    padding-top: 84px;
    padding-bottom: 66px;
  }

  .happy-customers-inner {
    width: 70%;
    margin: 0 auto;
  }

  .happy-customers-heading {
    display: flex;

    align-items: center;
    justify-content: center;
  }

  .happy-customers-heading h3 {
    font-size: 42px;
    font-family: Mulish;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.21px;
    color: #ffffff;
    text-align: center;
    margin: 0;
    margin-bottom: 57px;
  }

  .happy-customers-heading button {
    width: 10rem;
    height: 4rem;
    color: #ffffff;
    background-color: #1e2130;
    border: 1px solid #1e2130;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .happy-customers-heading button:hover {
    background-color: #1e2140;
    transform: scale(1.1);
    transition: all 0.1s;
  }

  .happy-customers-heading button img {
    margin-left: 1rem;
    width: 0.7rem;
  }

  .happy-customers-heading h3 span {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .happy-customers-items {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1fr 1fr 1fr;
    width: 1063px;
    margin: 0 auto;
    /* place-items: center; */
  }
  .happy-customers-items::-webkit-scrollbar {
    width: 0em !important;
  }
  .happy-customers-items::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  .happy-customers-items::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }

  .faq-section {
    width: 70%;
    margin: 0 auto;
    padding-top: 65px;
    padding-bottom: 125px;
  }

  .faq-section h3 {
    text-align: center;
    font-size: 42px;
    font-family: Mulish;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: -0.21px;
    margin: 0;
    margin-bottom: 53px;
  }
  .card {
    overflow: hidden;
  }

  footer {
    background-color: #1e2130;
    padding: 2rem 0 2rem 0;
  }

  .footer-container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .footer-column {
    width: 100%;
    margin-bottom: 2rem;
  }

  .footer-column h3 {
    font-size: 2.5rem;
    color: #ffffff;
  }

  .footer-column h6 {
    font-size: 2rem;
    margin: 1rem 0;
    color: #ffffff;
  }
  .footer-column ul {
    margin: 0;
    padding: 0;
  }
  .footer-column ul li {
    list-style: none;
    margin: 1rem 0;
  }

  .footer-links {
    text-decoration: none;
    font-size: 1.8rem;
    color: #888eaa;
  }

  .footer-btn {
    padding: 1rem;
    padding-left: 0px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  .footer-btn img:first-child {
    margin-right: 1rem;
  }

  .social-media-links {
    display: flex;
  }
  .social-media-links .footer-links {
    margin-right: 1rem;
  }

  .copyright {
    border-top: 2px solid #3f4356;
  }

  .copyright-inner {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 10fr 2fr;
  }
  .copyright-inner a {
    font-size: 1.4rem;
    color: #888eaa;
    margin-top: 3rem;
    font-weight: 800;
  }

  .copyright-inner p {
    font-size: 1.4rem;
    color: #888eaa;
    margin-top: 3rem;
    font-weight: 600;
  }
  .developed a {
    color: #ffffff;
    /* padding: 10px;
    border-radius: 10px;
    background-color: #ffffff; */
    font-size: 20px;
    text-decoration: none;
    margin-left: 10px;
  }
  .developed {
    text-align: left;
    color: white;
    font-weight: 800;
    font-size: 16px;
  }

  .tata-digital {
    font-weight: 100;
    font-size: 1.6rem !important;
    color: #ffffff !important;
    text-align: right;
  }
  .tata-digital span {
    font-weight: 900;
  }

  @media (max-width: 1650px) {
    .truck-brand-items {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @media (max-width: 1540px) {
    .buyguide-section {
      margin: 0 2rem;
      /* height: 655px; */
    }

    .buyguide-heading div img {
      width: 80%;
    }

    .buyguide-img img {
      width: 100%;
      position: relative;
      top: 20px;
    }

    .truck-brand-items {
      grid-template-columns: repeat(4, 1fr);
    }
    .cavalo-deals-blog {
      width: 80%;
    }

    .happy-customers-inner {
      width: 80%;
    }

    .footer-column h3 {
      font-size: 2rem;
      color: #ffffff;
    }
  }
  @media (max-width: 1200px) {
    .get-estimate-img {
      width: 180px;
    }
    .explore-truck-inner-prod {
      width: 85%;
      /* overflow-x: scroll; */
    }
    .explore-truck-items-prod {
      width: 100%;
      gap: 21.33px;
      align-items: flex-start;
      overflow-x: scroll;
    }
  }
  @media (max-width: 1100px) {
    .explore-truck-inner {
      width: 75%;
      /* overflow-x: scroll; */
    }
    .explore-truck-items {
      overflow-x: scroll;
    }
  }

  @media (max-width: 1000px) {
    .services-section h3 {
      font-size: 4rem;
    }
    .services-inner {
      width: 100%;
      overflow-x: scroll;
      padding-left: 44px;
    }

    .buyguide-section {
      margin: 0 2rem;
    }

    .buyguide-heading div {
      width: 90%;
    }

    .buyguide-heading div h2 {
      font-size: 3rem;
    }
    .buyguide-heading div img {
      width: 70%;
    }

    .truck-brand-items {
      grid-template-columns: repeat(3, 1fr);
    }
    .sell-oldtruck-section {
      margin: 7rem 0;
    }

    .cavalo-deals-blog {
      width: 90%;
    }

    .get-estimate h3 {
      font-size: 3.3rem;
    }
    .oldtruck-blogs h3 {
      font-size: 2.8rem;
    }
    .happy-customers-heading h3 {
      font-size: 3.5rem;
    }

    .happy-customers-items {
      grid-gap: 2rem;
      overflow: scroll;
      width: 100%;
      height: 380px;
    }
    .happy-customers-inner {
      width: 90%;
    }

    .contactus-strip {
    }
    .cavalo-deals-blog {
      width: 90%;
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      margin: 0 auto;
    }
  }

  @media (max-width: 767px) {
    .mid_section {
      display: flex;
      flex-direction: column;
    }
    .footer-column {
      width: 100%;
      margin-bottom: 0;
      /* height: fit-content; */
    }

    .services-inner {
      width: 100%;
      overflow-x: scroll;
    }
    .services-items {
      min-width: 50rem;
      /* margin-left: 25%; */
      /* width: 4px; */
      width: 900px;
      gap: 18px;
    }
    .card_sections {
      padding: 5vh 0;
      width: 100vw;
     margin: 2rem auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .services-section h3 {
      font-size: 4rem;
      margin-top: 51px;
    }
    .services-section {
      gap: 40px;
      height: 500px;
    }

    .buyguide-section {
      height: 610px;

      margin-top: 48px;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;

      scroll-snap-align: start;
    }

    .buyguide-heading div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }

    .buyguide-heading div h2 {
      text-align: center;
      font-size: 28px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      letter-spacing: -0.14px;
      width: 100%;
      text-align: center;
      margin-bottom: 4rem;
    }

    .buyguide-heading div img {
      width: 70%;
    }

    .buyguide-img img {
      width: 100%;
      position: relative;
      bottom: 0px;
    }
    .explore-truck-section {
      position: relative;
      padding: 41px 0 38px;
      scroll-snap-align: start;
      min-height: 667px;
      /* margin-top: 48px; */
    }
    .explore-truck-inner {
      width: 90%;
    }
    .explore-truck-inner-prod {
      width: 90%;
    }

    .explore-truck-sections{
      padding: 30px 0px 30px;
      min-height: 620px;
    }

    .explore-truck-heading button {
      position: absolute;
      bottom: 2.5rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .explore-truck-heading button:hover {
      transform: translateX(-50%) scale(1.1);
      background-color: #1e2140;
    }

    .explore-truck-items {
      width: 100%;
      gap: 21.33px;
      align-items: flex-start;
      overflow-x: scroll;
    }
    .explore-truck-items-prod {
      width: 100%;
      gap: 21.33px;
      align-items: flex-start;
      overflow-x: scroll;
    }

    .explore-truck-heading h3 {
      font-size: 28px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      letter-spacing: -0.14px;

      width: 100%;
      text-align: center;
    }

    .trucks-by-brand-heading {
      flex-direction: column;
    }
    .trucks-by-brand-heading h3 {
      font-size: 2.8rem;
      text-align: center;
    }

    .trucks-by-brand-inner {
      width: 85%;
      margin: 0 auto;
    }
    .truck-brand-items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .trucks-by-brand-section {
      margin-top: 5rem;
      position: relative;
    }

    .trucks-by-brand-heading button {
      /* margin-top:rem; */
      position: absolute;
      bottom: -7rem;
      padding: 10px 12px;
    }

    .sell-oldtruck-section h2 {
      margin: 7.3rem 0 36px 0;
      text-align: center;
      font-size: 28px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      letter-spacing: -0.14px;
    }

    .get-estimate {
      height: 35rem;
    }

    .get-estimate button {
      padding: 1.5rem 1rem;

      width: 135px;
      background-color: #f8b301;
      border: 1px solid #f8b301;
      border-radius: 0.8rem;
      position: absolute;
      bottom: 3rem;
      font-size: 12.277px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .get-estimate button img {
      width: 16.369px;
      height: 16.369px;
    }
    .get-estimate-arrow {
      position: absolute;
      top: 52%;
      /* left: 50%; */
      left: 38%;
      height: 50px;
      transform: none;
    }
    .happy-customers-heading h3 {
      text-align: center;
      font-size: 28px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      letter-spacing: -0.14px;
      margin-bottom: 41px;
    }

    .happy-customers-items {
      grid-gap: 16.428px;
    }

    .faq-section h3 {
      text-align: center;
      font-size: 28px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      letter-spacing: -0.14px;
      width: 252px;
      margin: 0 auto;
      margin-bottom: 46px;
    }

    .faq-section {
      width: 90%;
      /* margin: 7rem auto; */
    }

    .rc-faq-toggle {
      padding: 1rem;
      margin: 1rem 0;
    }

    .rc-faq-title {
      font-weight: 700;
      font-size: 16px;
      margin: 1rem 0;
    }
    .copyright-inner p {
      text-align: center;
      color: #888eaa;
      text-align: center;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.56px;
    }

    .rc-faq-body p {
      font-size: 1.3rem;
    }

    .footer-container {
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      grid-template-rows: 1fr 1fr;
      margin-bottom: 2rem;
    }
    .footer-btn img {
      width: 5rem;
    }

    .footer-column h3 {
      font-size: 1.4rem;
    }

    .footer-links {
      font-size: 1.3rem;
    }
    .tata-digital {
      /* display: none; */
    }

    .copyright-inner {
      grid-template-columns: 1fr;
      place-items: center;
    }
    /* .happy-customers-items {
      width:200px;
    } */
  }
  @media (max-width: 414px) {
    .buyguide-heading div img {
      width: 85%;
    }
    .get-estimate {
      background-position: -40px 0;
      background-size: cover;
      width: 290px;
      height: 272px;
      margin: 0 auto;
      padding: 28.645px 21.825px 24.553px 21.825px;
      position: relative;
    }
    .get-estimate h6 {
      font-size: 9.548px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.146px;
      margin: 0;
    }
    .get-estimate h3 {
      font-size: 28.645px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.143px;
      margin-top: 5.5px;
    }
    .get-estimate-img {
      position: absolute;
      bottom: -2px;
      right: -26px;
      width: 118.163px;
      /* height: 72.869px; */
    }
    .oldtruck-blogs {
      width: 290px;
      height: 272px;
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 12.277px;
      padding: 28.645px 21.825px 24.553px 21.825px;
    }
    .oldtruck-blogs .get-estimate-img {
      display: block;
      opacity: 50%;
    }
    .oldtruck-blogs h3 {
      font-size: 28.645px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      letter-spacing: -0.143px;
    }
    .oldtruck-blogs button {
      padding: 11.595px 16.369px 11.595px 20.461px;
      justify-content: center;
      align-items: center;
      gap: 5.456px;
      font-size: 12.277px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: fit-content;
    }
    .oldtruck-blogs h6 {
      margin: 0;
      color: rgba(30, 33, 48, 0.75);
      font-size: 9.548px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.146px;
    }
    .footer-btn {
      flex-direction: column;
      gap: 10px;
    }
    .footer-btn img {
      width: 100%;
    }
  }
  @media (max-width: 380px) {
    .footer-btn img {
      width: 5rem;
    }

    .footer-column h3 {
      font-size: 1.3rem;
    }

    .footer-links {
      font-size: 1.2rem;
    }
  }
`;

export default Wrapper;
