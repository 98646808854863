import React from 'react'
import ReactSpeedometer from 'react-d3-speedometer';

const Speedometer = ({value,height,width}) => {
  console.log(height,width)
  return (
    <ReactSpeedometer
      maxValue={900}
      minValue={300}
      value={value}
      needleColor="black"
      segments={4}
      segmentColors={["#e42127", "#f19118", "#b2ae0d", "#0b9547"]}
      ringWidth={height*.56}
      needleHeightRatio={0.7}
      customSegmentLabels={[
        {
          text: "POOR",
          position: "OUTSIDE",
          color: "#000",
        },
        {
          text: "AVERAGE",
          position: "OUTSIDE",
          color: "#000",
        },
        {
          text: "GOOD",
          position: "OUTSIDE",
          color: "#000",
        },
        {
          text: "EXCELLENT",
          position: "OUTSIDE",
          color: "#000",
        },
        
      ]}
      width={width}
      height={height}
    />
  );
}

export default Speedometer