import React from "react";
import DarkRightArrow from "../assets/darkRightArrow.svg";
import PowerTrac from "../assets/PowerTrac.png";
import BrandItem from "./BrandItem.jsx";
import E from "../assets/E.png";
import Vst from "../assets/Vst.png";
import John from "../assets/John.png";
import Kubata from "../assets/Kubata.png";
import Massy from "../assets/Massy.png";
import Holland from "../assets/Holland.png";
import Swaraj from "../assets/Swaraj.png";

const TrucksByBrand = () => {
  return (
    <div className="trucks-by-brand-section">
      <div className="trucks-by-brand-inner">
        <div className="trucks-by-brand-heading">
          <h3>
            Browse <span>top trucks</span> by brands
          </h3>
          <button className="">
            View All <img src={DarkRightArrow} alt="right arrow" />
          </button>
        </div>
        <div className="truck-brand-items">
          <BrandItem BrandName={PowerTrac} />
          <BrandItem BrandName={E} />
          <BrandItem BrandName={Vst} />
          <BrandItem BrandName={John} />
          <BrandItem BrandName={Kubata} />
          <BrandItem BrandName={Massy} />
          
        </div>
        <div className="truck-brand-items">
          
          <BrandItem BrandName={Holland} />
          <BrandItem BrandName={Swaraj} />
          <BrandItem BrandName={PowerTrac} />
        </div>
      </div>
    </div>
  );
};

export default TrucksByBrand;
