import React from "react";

import "./InfoCard.css";

const InfoCard = ({number}) => {
  return (
    <div className="info_card">
      <div className="profile-container">
        <div>
          <span className="main_heading heading" style={{ color: "#F8B301" }}>
            {number}
          </span>
        </div>
        <div className="sub_heading1 heading">
          The{" "}
          <span style={{ color: "#F8B301", margin: "0px 13px" }}>
            {" "}
            most trusted destination{" "}
          </span>{" "}
          for refurbished trucks
        </div>

        <p className="info-text">
          At Cavalo, we strive to be the most trusted destination for
          refurbished trucks in India. We are committed to providing a reliable,
          transparent, and convenient platform for buying and selling
          refurbished trucks. Our team of experts thoroughly inspects each truck
          to ensure that it meets the highest standards of quality, and we offer
          a wide range of filters and search options to help you find the
          perfect truck. With Cavalo, you can trust that you are getting a
          refurbished truck that you can rely on.
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
