import React from 'react'
import styled from 'styled-components';
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import animation from '../assets/animatino.json'

const Loader = () => {
    const LoadWrapper = styled.div`
      fill: #f8b301 !important;
    `;
  return (
    <LoadWrapper>
      <Player
        autoplay
        loop
        src={animation}
        style={{ height: "300px", width: "300px", fill: "#F8B301" }}
      ></Player>
    </LoadWrapper>
  );
}

export default Loader