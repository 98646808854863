import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {createClient} from "contentful"
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import BlogCardComponent from "./BlogCardComponent/BlogCardComponent";
import { ReactComponent as Contact } from "../../assets/CallIcon.svg";
import BlogImage from "../../assets/blog-image-1.jpg"
import "./BlogComponent.css";
import Loader from "../Loader";

const BlogComponent = () => {

  const client = createClient({ space: "n2g35udzh8t2", accessToken: "-aqPy5ucn5XxZ8HZEaMniVhDinNC9tIhpsa5uVbmqQ0" })
  const [list, setList] = useState([]);
  const [perPageCardCount, setPerPageCardCount] = useState(12);
  const [selectedPage, setSelectedPage] = useState(0);
  const [loadingBlogList,setLoadingBlogList] = useState(false)

  const [selectedPopularTags,setSelectedPopularTags] = useState([])

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Blog" },
  ];

  useEffect(() => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 2560) {
      setPerPageCardCount(20);
    } else if (windowWidth >= 1700) {
      setPerPageCardCount(16);
    } else if(windowWidth >1024) {
      setPerPageCardCount(12);
    }else {
      setPerPageCardCount(6)
    }
  }, []);


  useEffect(() => {

    if(list.length === 0) {
      const getAllBlogs = async () => {
        try {
          setLoadingBlogList(true)
          await client.getEntries().then((entries) => {
            setList(entries)
            setLoadingBlogList(false)
          })
        } catch (error) {
          console.log(`Error fetching authors ${error}`);
          setList([])
          setLoadingBlogList(false)
        }
      };
      getAllBlogs()
    }
    
  }, [client])





  // useEffect(()=>{
  //   let eml = document.getElementById("blog-card-box").getBoundingClientRect()
  //   console.log(eml)

  //   window.scrollTo({top:eml.top,behavior:"smooth"})
  // },[selectedPage])

  window.onresize = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 2560) {
      setPerPageCardCount(20);
    } else if (windowWidth >= 1700) {
      setPerPageCardCount(16);
    }  else if(windowWidth >1024) {
      setPerPageCardCount(12);
    }else {
      setPerPageCardCount(6)
    }
  };

  const handlePageChange = (value) => {
    setSelectedPage(value.selected);
  };


  const handlePopularTagSelection = (value) => {
     let tempSelectedPopularTags = [...selectedPopularTags]
      const index = tempSelectedPopularTags.findIndex((item)=> item === value)
      if(index === -1){
        tempSelectedPopularTags.push(value)
      }else {
        tempSelectedPopularTags.splice(index,1)
      }

      setSelectedPopularTags(tempSelectedPopularTags)
  }


  if(loadingBlogList){
    return <div className="progress">
     <Loader />
   </div>
   }

  return (
    <div className="wrapper">
      <BreadCrumbs breadcrumbs={breadcrumbs} />

      <div
      className="blog-section1"
      >
      <img src={BlogImage} style={{width:"100%",height:'100%'}}/>
      <div className="static-card-content">
      <h2>Cavalo <span
      style={{ color: "#F8B301", }}
    > Blog </span></h2>
       <p>Explore the latest insights, innovations, and news about Cavalo in our blogs, your go-to source for staying informed and connected with all things Cavalo!</p>
      
      </div>
      </div>

      <div >
        <h2 className="sub-heading">Latest Blogs</h2>
        <div className="tag-container">
          <span className="tag-heading">Popular Tags:</span>
          <div className="sub-tag-container">
          {["TRUCKS",'TRANSPORTATION',"MARKETING","TECHNOLOGY","USER GUIDES"].map((item)=>(
            <span onClick={()=>handlePopularTagSelection(item)} className={`${selectedPopularTags.includes(item) ? "highlighted-tag": "non-highlighted-tag"}`}>{item}</span>
          ))}
          </div>
        </div>
      </div>

      <div className="blog-card-container" id="blog-card-box">
        {list?.items?.filter(item=> {
          if(selectedPopularTags.length> 0){
            return selectedPopularTags.includes(item?.fields?.cavaloPopularTag?.toUpperCase())
          }else {
            return item
          }
        })?.slice(
            selectedPage * perPageCardCount,
            (selectedPage + 1) * perPageCardCount
          )
          .map((item, index) => {
            console.log(item.fields.blogImage.fields.file.url)
            return (
              <BlogCardComponent
                post={item}
                key={item.sys.id}
                index={index + selectedPage * perPageCardCount}
              />
            );
          })}
      </div>



      <div className="pagination-container">
        <ReactPaginate
          nextLabel="Next  >"
          breakLabel="..."
          onPageChange={handlePageChange}
          pageRangeDisplayed={2}
          activeClassName="activeClassName"
          pageCount={Math.ceil((list?.items?.length || [].length) / perPageCardCount)}
          previousLabel={selectedPage === 0 ? "" : "< Previous"}
          renderOnZeroPageCount={null}
        />
      </div>

      <div className="section-2">
        <h3>GET IT FINANCED</h3>

        <div className="">
          <p>
            Get behind the wheel of your dream <br /> truck with our{" "}
            <span style={{ color: "#F8B301", margin: "0px 10px" }}>
              {" "}
              hassle-free EMI options!{" "}
            </span>{" "}
          </p>
          
           <div className="btn-container">
            <button className="know-more">Know More {">"}</button>
            <button className="contact-us">
              <Contact /> <span>Contact Us</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogComponent;
