
import React from "react"
import {ReactComponent as Icon} from "../../assets/flat-color-icons_approval.svg"
import "./SubmitSection.css"



const SubmitSection = () => {

    return <div className="submit-section">

               <Icon/>


               <h2>Thank you for submitting your Requirement Form!</h2>
                  <p>Our specialists will carefully analyze your specifications and get back to you as soon as possible with tailored options that match your criteria. If we require any additional details, we will reach out to you using your preferred contact method.</p>    
    
    </div>

     
}




export default SubmitSection 