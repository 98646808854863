import React, { useState } from 'react';
import './RcDetails.css';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const RcDetails = ({ onChnageHandler }) => {
  const [rcStatus, setRcStatus] = useState('yes');
  const [permitType, setPermitType] = useState('');
  const [insuranceValidity, setInsuranceValidity] = useState(null);
  const [taxValidity, setTaxValidity] = useState(null);
  const [fitnessValidity, setFitnessValidity] = useState(null);

  const handleRcInput = (rcStatusInfo) => {
    setRcStatus(rcStatusInfo);
    onChnageHandler({
      target: {
        name: "rcStatus",
        value: rcStatusInfo
      }
    });
  }

  const handlePermitType = (e) => {
    const permitTypeInfo = e.target.value;
    setPermitType(permitTypeInfo);
    onChnageHandler({
      target: {
        name: "permitType",
        value: permitTypeInfo
      }
    });
  }

  const handleInsuranceValidity = (date) => {
    setInsuranceValidity(date);
    const formattedDate = date.format('YYYY-MM-DD');
    onChnageHandler({
      target: {
        name: "insuranceValidity",
        value: formattedDate
      }
    });
  }

  const handleTaxValidity = (date) => {
    setTaxValidity(date);
    const formattedDate = date.format('YYYY-MM-DD');
    onChnageHandler({
      target: {
        name: "taxValidity",
        value: formattedDate
      }
    });
  }

  const handleFitnessValidity = (date) => {
    setFitnessValidity(date);
    const formattedDate = date.format('YYYY-MM-DD');
    onChnageHandler({
      target: {
        name: "fitnessValidity",
        value: formattedDate
      }
    });
  }

  return (
    <div className='rc-details-main-box'>
      <h2 className="main-heading">RC Details</h2>

      <div className="rc-fields">
        <div className="rc-page-status-box">
          <span>Rc Status</span>
          <div className='rc-btn-container'>
            <div
              className={`rc-btn ${rcStatus === 'yes' ? 'selected-btn' : ''}`}
              onClick={() => handleRcInput('yes')}
            >
              <span>yes</span>
            </div>
            <div
              className={`rc-btn ${rcStatus === 'no' ? 'selected-btn' : ''}`}
              onClick={() => handleRcInput('no')}
            >
              <span>no</span>
            </div>
          </div>
        </div>
        <div className="rc-permit">
          <select onChange={handlePermitType}>
            <option value="">Select Permit</option>
            <option value="state">State Permit</option>
            <option value="national">National Permit</option>
            <option value="local">Local Permit</option>
          </select>
        </div>

        <div className="fitness-column">
          <div className='fitness-inputs'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="Fitness Validity"
                  value={fitnessValidity}
                  onChange={handleFitnessValidity}
                  InputLabelProps={{ className: 'text_field_label' }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className='fitness-inputs'>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={['DatePicker']} >
                <DatePicker label="Insurance Validity"
                  value={insuranceValidity}
                  onChange={handleInsuranceValidity}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className='fitness-inputs'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker label="Tax Validity"
                  value={taxValidity}
                  onChange={handleTaxValidity}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RcDetails;
