import React, { useState } from "react";
import { ReactComponent as Bus } from "../../assets/bus.svg";
import { ReactComponent as Truck } from "../../assets/TruckVeqSvg.svg";
import "./PersonalDetailSection.css";
import "./VehicleDetailsSection.css";

const VehicleDetailsSection = ({
  data,
  onChnageHandler,
  bodyType,
  payload,
  make,
  model,
}) => {
  console.log("final data:", data);
  const yearList = [];
  for (let year = 2000; year <= 2024; year++) {
    yearList.push({ id: year, year: year });
  }

  return (
    <div className="personal-detail-main-box">
      <h2>Lets Choose Your Dream Ride</h2>

      <div className="vehicle-option-btn-container">
        <div
          className={`vehicle-option-btn ${
            data?.type ===
            "Truck+,Pickup+,Trailer+,Mini Truck+,Transit Mixer+,Tipper"
              ? "enabled-btn"
              : "disabled-btn"
          }`}
          onClick={() => {
            onChnageHandler({
              target: {
                name: "type",
                value:
                  "Truck+,Pickup+,Trailer+,Mini Truck+,Transit Mixer+,Tipper",
              },
            });
          }}
        >
          <Truck />
          <span>Trucks</span>
        </div>
        <div
          className={`vehicle-option-btn ${
            data?.type === "bus" ? "enabled-btn" : "disabled-btn"
          }`}
          onClick={() => {
            onChnageHandler({
              target: {
                name: "type",
                value: "bus",
              },
            });
          }}
        >
          <Bus />
          <span>Bus</span>
        </div>

        {/* <div className={`vehicle-option-btn ${data?.type === "bus" ? "enabled-btn":"disabled-btn"}`} style={{marginLeft:"10px"}} onClick={()=> {
          onChnageHandler({
            target : {
              name:"type",
              value:"bus"
            }
          })
        }}>
          <Bus/>
          <span>Bus</span>
        </div>*/}
      </div>

      <div className="vehicle-detail-fields-box">
        <div className="field-icon-container make-grid-item">
          <select
            name="make"
            onChange={onChnageHandler}
            value={data?.make?.brand_id}
          >
            <option>Select Make </option>
            {make?.length > 0
              ? make.map((item) => (
                  <option value={item.brand_id}>{item.brand_name}</option>
                ))
              : null}
          </select>
        </div>

        <div className="field-icon-container model-grid-item">
          <select name="model" onChange={onChnageHandler} value={data?.model}>
            <option>Select Model </option>
            {model?.length > 0
              ? model.map((item) => (
                  <option value={item.model_id}>{item.model_name}</option>
                ))
              : null}
          </select>
        </div>

        <div className="field-icon-container bodyType-grid-item">
          <select
            name="bodyType"
            onChange={onChnageHandler}
            value={data?.bodyType}
          >
            <option value="">Body Type</option>
            {bodyType?.length > 0
              ? bodyType.map((item) => (
                  <option value={item.id}>{item.body_type}</option>
                ))
              : null}
          </select>
        </div>
        <div className="field-icon-container lengthType-grid-item">
          <select name="length" onChange={onChnageHandler} value={data?.length}>
            <option value="">Select Length (1 - 70 feet)</option>
            {[...Array(70)].map((_, index) => (
              <option key={index} value={`${index + 1}`}>
                {index + 1} feet
              </option>
            ))}
          </select>
        </div>
        <div className="field-icon-container capacityType-grid-item">
          <select
            name="capacity"
            onChange={onChnageHandler}
            value={data?.capacity}
          >
            <option value="">
              {data?.type === "bus"
                ? "Seating Capacity"
                : "Ton Capacity (1 - 50 tons)"}
            </option>
            {[...Array(42)].map((_, index) => (
              <option key={index} value={`${index + 1}`}>
                {data?.type === "bus"
                  ? `${index + 1} seats`
                  : `${index + 1} ton`}
              </option>
            ))}
          </select>
        </div>

        <div className="field-icon-container manufactureType-grid-item">
          <select
            name="manufactureYear"
            onChange={onChnageHandler}
            value={data?.manufactureYear?.id}
          >
            <option value="">Preferred Year Of Manufacture</option>
            {yearList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.year}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="additional-req-box">
        <p>Additional Requirements</p>
        <textarea
          rows={6}
          name="additionalInfo"
          onChange={onChnageHandler}
          value={data?.additionalInfo}
          placeholder="Please provide any specific details or requirements you may have"
        />
      </div>
    </div>
  );
};

export default VehicleDetailsSection;
