import React, { useEffect, useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import { ReactComponent as Bus } from "../../assets/bus.svg";
import { ReactComponent as Truck } from "../../assets/Truck.svg";
import "./PersonalDetailSection.css";
import "./FinanceSection.css";

const FinanceSection = ({ data, onChnageHandler }) => {
  // Initialize minValue and maxValue to the minimum and maximum possible values of the slider range
  const [minValue, setMinValue] = useState(10000);
  const [maxValue, setMaxValue] = useState(6000000);

  const [minValue2, setMinValue2] = useState(0);
  const [maxValue2, setMaxValue2] = useState(0);

  const caption = (value) => {
    let text = "";
    if (value >= 100000) {
      text = `Rs ${(value / 100000).toFixed(2)} Lakh`;
    } else {
      text = `RS ${(value / 1000).toFixed(2)} Thousands`;
    }
    return text;
  };

  useEffect(() => {
    let data = {
      target: {
        name: "range",
        value: {
          minValue,
          maxValue,
        },
      },
    };
    onChnageHandler(data);
  }, [minValue, maxValue]);

  return (
    <div className="personal-detail-main-box">
      <h2>Determine Your Budget Parameters Below</h2>

      <div className="finance-detail-fields-box">
        <MultiRangeSlider
          min={10000}
          max={6000000}
          step={1}
          minValue={minValue}
          maxValue={maxValue}
          minCaption={caption(minValue)}
          maxCaption={caption(maxValue)}
          labels={[caption(minValue), caption(maxValue)]}
          ruler="false"
          barLeftColor="#1E2130"
          barInnerColor="#1E2130"
          barRightColor="#1E2130"
          thumbLeftColor="#F8B301"
          thumbRightColor="#F8B301"
          canMinMaxValueSame={false}
          onInput={(e) => {
            setMinValue(e.minValue);
            setMaxValue(e.maxValue);
          }}
          onChange={(e) => {
            setMinValue2(e.minValue);
            setMaxValue2(e.maxValue);
          }}
        />

        <div className="field-icon-container financing-grid-item">
          <select
            name="financingOption"
            onChange={onChnageHandler}
            value={data?.financingOption}
          >
            <option>Financing Options</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
        <div className="field-icon-container time-grid-item">
          <select
            placeholder={"Body Type"}
            name="duration"
            onChange={onChnageHandler}
            value={data?.duration}
          >
            <option>Time Duration for Purchase</option>
            <option value="Within a week">Within a week</option>
            <option value="Within 15 days">Within 15 days</option>
            <option value="Within 30 days">Within 30 days</option>
            <option value="Flexible">Flexible</option>
            <option value="Just Enquiring">Just Enquiring</option>
          </select>
        </div>
      </div>

      <div className="auction-div">
        <p>Will you be interested in our Auctions?</p>
        <div className="">
          <select
            name="isAuction"
            onChange={onChnageHandler}
            value={data?.isAuction}
          >
            <option value="yes">Yes </option>
            <option value="no">No</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default FinanceSection;
