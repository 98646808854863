import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { root_url } from "../../utils/url.constant";

// import store from "../../app/store";

const URL = root_url;

export const sellSlice = createSlice({
  name: "sell",
  initialState: {
    allMakes: [],
    makeId: "",
    allYears: [],
    yearId: "",
    modelId: null,
    transmissionId: "",
    fuelId: "",
    variantId: "",
    allModels: [],
    allFuelAndTransmission: [],
    allFuel: [],
    allTransmission: [],
    allVariants: [],
    allEmissions: [],
    allTyreConditions: [],
    allColors: [],
    allBodyTypes: [],
    allCargoLengths: [],
    allPayloads: [],
    allKmDrivens: [],
    allOwners: [],
    sellStep1: "",
    sellStep2: "",
    sellStep3: "",
    rtoId: "",
    numberData: null,
  },
  reducers: {
    fetchMake: (state, action) => {
      state.allMakes = action.payload;
    },
    setNumberData: (state, action) => {
      state.numberData = action.payload;
    },
    setMakeId: (state, action) => {
      state.makeId = action.payload?.id ?? "";
    },
    fetchYear: (state, action) => {
      state.allYears = action.payload;
    },
    setYearId: (state, action) => {
      state.yearId = action.payload?.id ?? "";
    },
    setRtoId: (state, action) => {
      state.rtoId = action.payload?.id ?? 0;
    },
    setTransmissionId: (state, action) => {

      state.transmissionId = action.payload?.id ?? "";
    },
    setFuelId: (state, action) => {

      state.fuelId = action.payload?.id ?? "";
    },
    setVariantId: (state, action) => {
      state.variantId = action.payload?.id ?? "";
    },

    setModelData: (state, action) => {
      state.allModels = action.payload;
    },
    setModelId: (state, action) => {
      const modelId = action.payload?.id ?? 0;
      state.modelId = modelId;
    },
    setAllFuel: (state, action) => {
      state.allFuel = action.payload;
    },

    setVariantSpecs: (state, action) => {
      state.allVariants = action.payload;
      // state.modelId = action.payload[0]?.modelId ?? 0;
      // state.fuelId = action.payload[0].fuelType[0].id ?? 0;
      // state.transmissionId = action.payload[0].transmissionType[0].id ?? 0;
    },
    setAllEmissions: (state, action) => {
      state.allEmissions = action.payload;
    },
    setAllTyreConditions: (state, action) => {
      state.allTyreConditions = action.payload;
    },
    setAllColors: (state, action) => {
      state.allColors = action.payload;
    },
    setAllBodyTypes: (state, action) => {
      state.allBodyTypes = action.payload;
    },
    setAllCargoLengths: (state, action) => {
      state.allCargoLengths = action.payload;
    },
    setAllPayloads: (state, action) => {
      state.allPayloads = action.payload;
    },
    setAllKmDrivens: (state, action) => {
      state.allKmDrivens = action.payload;
    },
    setAllOwners: (state, action) => {
      state.allOwners = action.payload;
    },
    setAllTransmission: (state, action) => {
      state.allTransmission = action.payload;
    },
    setStep1: (state, action) => {
      state.sellStep1 = action.payload;
    },
    setStep2: (state, action) => {
      state.sellStep2 = action.payload;
    },
    setStep3: (state, action) => {
      state.sellStep3 = action.payload;
    },
  },
});

// Write a synchronous outer function that receives the `text` parameter:
export function GetMakes() {
  return async function GetMakesThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/make`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.fetchMake(data));
    } catch (err) {
    }
  };
}

export function GetYearByMake() {
  return async function GetYearByMakeThunk(dispatch, getState) {
    if (getState().sell.makeId === 0) return;
    try {
      const response = await axios.get(
        `${URL}/truck/year/make/${getState().sell.makeId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = response.data;
      dispatch(sellSlice.actions.fetchYear(data));
    } catch (err) {
    }
  };
}

export function GetModelByMakeAndYear() {
  return async function GetModelByMakeAndYearThunk(dispatch, getState) {
    if (getState().sell.makeId === 0 || getState().sell.yearId === 0) return;
    try {
      const response = await axios.get(
        `${URL}/truck/model/${getState().sell.makeId}/${getState().sell.yearId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = response.data;
      dispatch(sellSlice.actions.setModelData(data));
    } catch (err) {
    }
  };
}

export function GetVariantByMakeAndYear() {
  return async function GetVariantByMakeAndYearThunk(dispatch, getState) {
    try {
      if (
        getState().sell.makeId === 0 ||
        getState().sell.yearId === 0 ||
        getState().sell.modelId === 0
      )
        return;

      const response = await axios.get(
        `${URL}/truck/model/variant/${getState().sell.makeId}/${getState().sell.yearId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = response.data;
      const model = data?.filter(
        (model) => model.modelId === getState().sell.modelId
      )[0];
      dispatch(sellSlice.actions.setAllFuel(model.fuelType));
      dispatch(sellSlice.actions.setAllTransmission(model.transmissionType));

      // dispatch(GetVariantByModelFuelTransmission());
    } catch (err) {
    }
  };
}

export function GetVariantByModelFuelTransmission() {
  return async function GetVariantByModelFuelTransmissionThunk(
    dispatch,
    getState
  ) {
    //   }/${getState().sell.transmissionId}`, "variant")
    if (
      getState().sell.fuelId === 0 ||
      getState().sell.transmissionId === 0 ||
      getState().sell.modelId === 0
    ) return;

    try {
      const response = await axios.get(
        `${URL}/truck/variant/${getState().sell.modelId}/${getState().sell.fuelId
        }/${getState().sell.transmissionId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { data } = response.data;
      dispatch(sellSlice.actions.setVariantSpecs(data));
    } catch (err) {
    }
  };
}

export function GetAllEmission() {
  return async function GetAllEmissionThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/emission-standards`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.setAllEmissions(data));
    } catch (err) {
    }
  };
}

export function GetAllTyreCondition() {
  return async function GetAllTyreConditionThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/tyre-condition`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.setAllTyreConditions(data));
    } catch (err) {
    }
  };
}

export function GetAllColor() {
  return async function GetAllColorThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/color`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.setAllColors(data));

    } catch (err) {
    }
  };
}

export function GetAllBodyType() {
  return async function GetAllBodyTypeThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/body-type`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.setAllBodyTypes(data));
    } catch (err) {
    }
  };
}

export function GetAllCargoLength() {
  return async function GetAllCargoLengthThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/cargo-length`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.setAllCargoLengths(data));
    } catch (err) {
    }
  };
}

export function GetAllPayload() {
  return async function GetAllPayloadThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/payload`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.setAllPayloads(data));
    } catch (err) {
    }
  };
}

export function GetAllKmDriven() {
  return async function GetAllKmDrivenThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/km-driven`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.setAllKmDrivens(data));
    } catch (err) {
    }
  };
}

export function GetAllOwners() {
  return async function GetAllOwnersThunk(dispatch, getState) {
    try {
      const response = await axios.get(`${URL}/truck/owner`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data } = response.data;
      dispatch(sellSlice.actions.setAllOwners(data));
    } catch (err) {
    }
  };
}

// Action creators are generated for each case reducer function
export const {
  setMakeId,
  setYearId,
  setModelId,
  setAllFuel,
  setTransmissionId,
  setFuelId,
  setVariantId,
  setStep1,
  setStep2,
  setStep3,
  setRtoId,
  setModelData,
  setAllTransmission,
  setVariantSpecs,
  setNumberData,
} = sellSlice.actions;

export default sellSlice.reducer;
