import React, { useState } from "react";
import { BiHomeAlt } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import "./EmiCalculator.css";
import { ContactStrip, Footer, Navbar } from "../components";
import Wrapper from "../wrappers/Common";
import emi_img from "../assets/emi_img.webp";
import { Pie } from "react-chartjs-2";
import Select from "react-select";
import get_touch from "../assets/get_touch.webp";
import { BsTelephone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function EmiCalculator() {
  const [rate, setRate] = useState(10);
  const [months, setMonths] = useState("");
  const [amount, setAmount] = useState(500000);
  const [payment, setPayment] = useState(750000);
  const [years, setYears] = useState({
    value: 5,
    label: " 5 Years",
  });
  const [total, setTotal] = useState(0);
  const [installment, setIinstallment] = useState(250000);

  const data = {
    labels: ["Total Principal", "Total Interest"],
    datasets: [
      {
        label: "# of Votes",
        data: [amount, installment],
        backgroundColor: ["#F4BA00", "#2F80ED"],
        borderWidth: 0,
      },
    ],
  };

  const option = {
    plugins: {
      legend: {
        display: false, // This hides the legend
      },
    },
  };

  const styling = {
    control: (base) => ({
      ...base,
      boxShadow: "none !important",
      padding: "9px 18px !important",
      borderRadius: "8px !important",
      border: "1px solid rgba(226, 226, 226, 1) !important",
      gap: "10px !important",
      width: "100% !important",
      minHeight: "unset !important",
      "&:hover": {
        border: "1px solid rgba(226, 226, 226, 1) !important",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000 !important",
      "&hover": {
        color: "#000 !important",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none !important",
    }),

    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px !important",
      fontWeight: "400 !important",
      fontFamily: "Mulish !important",
      color: "#828282 !important",
      margin: "0px !important",
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "0px !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      color: "#212121 !important",
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      color: "#09021C !important",
      fontFamily: "var(--roboto) !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      background: isFocused
        ? "rgba(237, 237, 237, 1) !important"
        : isSelected
        ? "rgba(237, 237, 237, 1) !important"
        : "white",
      cursor: "pointer !important",
    }),
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  function calculate_emi(princ, intr, months, procFee) {
    var i = intr / 12 / 100;
    var result = {};
    result.emi = (princ * i) / (1 - Math.pow(1 / (1 + i), months));
    result.processingFee = (procFee * princ) / 100;

    result.totalIntrest = result.emi * months - princ;
    result.totalPayment = result.emi * months;
    result.quaterAmt = princ / 4;

    result.q1 = 0;
    result.q2 = 0;
    result.q3 = 0;
    result.q4 = 0;

    var x = result.totalPayment;

    result.row = new Array();
    result.anual = new Array();
    result.row[0] = {};

    result.row[0].os = x;
    result.row[0].pb = princ;
    result.row[0].ip = princ * i;
    result.row[0].pp = result.emi - result.row[0].ip;

    var pos = 1;
    while (x > 0) {
      x = x - result.emi;
      result.row[pos] = {};
      result.row[pos].os = result.row[pos - 1].os - result.emi;
      result.row[pos].pb =
        result.row[pos - 1].pb - result.emi + result.row[pos - 1].ip;
      result.row[pos].ip = result.row[pos].pb * i;
      result.row[pos].pp = result.emi - result.row[pos].ip;

      if (result.q1 == 0 && result.row[pos].pb < result.quaterAmt * 3) {
        result.q1 = pos;
      }
      if (result.q2 == 0 && result.row[pos].pb < result.quaterAmt * 2) {
        result.q2 = pos - result.q1;
      }
      if (result.q3 == 0 && result.row[pos].pb < result.quaterAmt) {
        result.q3 = pos - result.q2 - result.q1;
      }
      if (result.q4 == 0 && result.row[pos].pb < 0) {
        result.q4 = pos - result.q3 - result.q2 - result.q1;
      }

      pos = pos + 1;
    }
    var tempIP = 0;
    var tempPP = 0;
    for (i = 0; i < result.row.length - 1; i++) {
      tempIP = tempIP + result.row[i].ip;
      tempPP = tempPP + result.row[i].pp;
      if ((i + 1) % 12 == 0) {
        var posYear = parseInt(i / 12);
        result.anual[posYear] = {};
        result.anual[posYear].ip = tempIP;
        result.anual[posYear].pb = result.row[posYear * 12].pb;

        result.anual[posYear].pp = tempPP;

        tempIP = 0;
        tempPP = 0;
      }
    }

    return result;
  }
  const handleClick = () => {
    let months = years?.value * 12;

    if (
      !isNaN(amount) &&
      !isNaN(rate) &&
      !isNaN(months) &&
      amount !== "" &&
      months !== "" &&
      rate !== ""
    ) {
      var v = calculate_emi(amount, rate, months, 0);
      setIinstallment(v.totalIntrest.toFixed(2));
      setPayment(v.totalPayment.toFixed(2));
      setTotal(v.emi);
    }
  };
  return (
    <>
      <Wrapper>
        <Navbar />

        <div className="calculator">
          <div className="container">
            <div className="bread_crumb">
              <a href="/" className="home_icon">
                <BiHomeAlt />
              </a>
              <IoIosArrowForward className="arrow_icon" />
              <span>EMI Calculator</span>
            </div>

            <div className="main_content">
              <h1 className="heading">EMI Calculator</h1>

              <div className="image_box">
                <img src={emi_img} className="image" />
              </div>

              <div className="pie_box">
                <h6>EMI Calculator</h6>

                <div className="pie_chart">
                  <div className="chart">
                    <Pie data={data} options={option} />
                  </div>

                  <div className="right_side">
                    <div className="top_sec">
                      <div className="tags">
                        <p
                          className="circle"
                          style={{ background: "#F4BA00" }}
                        ></p>
                        <span>Breakup of Total Principal</span>
                      </div>

                      <div className="tags">
                        <p
                          className="circle"
                          style={{ background: "#2F80ED" }}
                        ></p>
                        <span>Total Interest</span>
                      </div>
                    </div>

                    <div className="inputs">
                      <input
                        type="number"
                        value={amount}
                        min={10000}
                        onChange={(e) => setAmount(Number(e.target.value))}
                        placeholder="Enter Down Payment (In ₹)*"
                        className="input"
                      />
                      <label style={{ fontSize: 14 }}>
                        Enter Interest Rate in Percent(%)
                      </label>
                      <input
                        type="number"
                        min={1}
                        value={rate}
                        onChange={(e) => setRate(Number(e.target.value))}
                        placeholder="Enter Interest Rate in Percent(%)"
                        className="input"
                        max={15}
                      />
                      <Select
                        value={years}
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el) => ({
                          label: el + el > 1 ? el + "Years" : el + "Year",
                          value: el,
                        }))}
                        onChange={(val) => setYears(val)}
                        placeholder="Select Term/Period(Months)*"
                        styles={styling}
                      />
                      <button
                        onClick={handleClick}
                        type="button"
                        style={{
                          borderRadius: 20,
                          color: "#fff",
                          border: "none",
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 15,
                          paddingBottom: 15,
                          backgroundColor: "#f4ba00",
                          fontSize: 16,
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        Calculate
                      </button>
                    </div>
                  </div>
                </div>

                <div className="bottom_sec">
                  <div className="first">
                    <p>Total Interest Payment</p>
                    <span className="first_span">₹{installment}</span>
                  </div>
                  <div className="first">
                    <p>Total Amount to Pay</p>
                    <span className="second_span">₹{payment}</span>
                  </div>
                  <div className="second">
                    <div className="main">
                      <span className="head">EMI</span>
                      <span className="text">Monthly Payment</span>
                    </div>
                    <div className="main2">
                      <span className="price">
                        ₹{total.toFixed(2)}{" "}
                        <span style={{ fontSize: "12px" }}>per month</span>
                      </span>
                      <span className="text2">Calculated on On Road Price</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="get_in_touch">
                <div className="left_content">
                  <h5>Get in touch</h5>
                  <p className="para">We’d love to hear from you</p>

                  <a href="tel:919876543210" className="links">
                    <BsTelephone />
                    +91 9876543210
                  </a>
                  <a href="mailto:support@cavalo.in" className="links">
                    <FiMail />
                    support@cavalo.in
                  </a>
                </div>

                <div className="right_content">
                  <img src={get_touch} alt="" className="image2" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ContactStrip />
        <Footer />
      </Wrapper>
    </>
  );
}
