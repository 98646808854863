import React from "react";
import rightArrow from "../assets/rightArrow.svg";
import TagIcon from "../assets/TagIcon.svg";
import styled from "styled-components";
import Wrapper from "../wrappers/Common";
import truckImg from "../assets/productDes.svg"
import arrow from '../assets/arrow-2-arrow-2.png'
import { useNavigate } from "react-router-dom";
import truck_img from '../assets/truck_img.webp';
import truck_video from '../assets/truck_video.mp4';


const SellOldTruck = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <div className="sell-oldtruck-section">
        <h2>Sell your old trucks</h2>
        <div className="cavalo-deals-blog">

          {/* <div className="get-estimate">
            <h6>CAVALO DEALS</h6>
            <h3>
              Get <span>best value</span> for <br /> your truck in just <br /> 5
              mins!
            </h3>
            <img src={arrow} alt="" className="get-estimate-arrow" />
            <button onClick={()=>{
              navigate('/estimate')

            }}>
              <img src={TagIcon} alt="tag icon" /> Get estimate
            </button>
            <img src={truckImg} alt="truckImg" className="get-estimate-img" />
          </div> */}

          <video poster={truck_img} style={{ width: '100%', objectFit: 'cover', border: '1px solid #d4d4d4', borderRadius: '0.8rem' }} controls >
            <source src={truck_video} type="video/mp4" style={{ width: '100%', height: '100%' }} />
          </video>

          <div className="oldtruck-blogs">
            <h6>Cavalo Blogs</h6>
            <h3>
              Best practices <br /> to know when <br /> selling your <br />
              truck!
            </h3>

            <a href="/blogs" style={{
              width: '20rem',
              marginTop: '20px',
              fontSize: '1.9rem',
              fontWeight: '500',
              background: '#1e2130',
              border: '1px solid #1e2130',
              padding: '2rem 1rem',
              borderRadius: '0.8rem',
              color: '#fff',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '10px',
              justifyContent:'center',
              textDecoration:'none'
            }}>
              Know More <img src={rightArrow} alt="right arrow" />
            </a>
            <img src={truckImg} alt="truckImg" className="get-estimate-img" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SellOldTruck;
