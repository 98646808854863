import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DropDown from "../assets/DropDown.svg";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { get } from "../utils/functions";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  cargoRangeChange,
  fuelAdded,
  kmAdded,
  makeAdded,
  payloadRangeChange,
  rangeChange,
  yearAdded,
} from "../features/explore/exploreSlice";
import { CircularProgress } from "@mui/material";

const Wrapper = styled.div`
  .nav-container {
    background-color: #ffffff;
  }

  body::-webkit-scrollbar {
    width: 1rem;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(255, 25, 0);
  }

  body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .dropdown-content::-webkit-scrollbar-thumb {
    height: 0.5rem;
  }

  .nav-inner {
    width: 75%;
    /* margin-left: 5%; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 328px 10px 80px;
    align-items: center;
    gap: 30px;
  }

  .nav-inner h4 {
    color: #757575;
    margin-right: 2rem;
    color: #757575;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 600;
    letter-spacing: 0.56px;
    text-transform: uppercase;
  }
  .buttons {
    max-width: 782px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    justify-content: space-evenly;
    width: 100%;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-button {
    background-color: #fff;
    border: none;
    /* color: #000; */
    padding: 10px 10px;
    /* font-size: 16px; */
    cursor: pointer;
    outline: none;
    border-radius: 4px;

    width: max-content;
    color: #000;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 600;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }

  .dropdown-button img {
    margin: auto 5px;
    width: 12px;
  }

  .dropdown-content {
    margin-top: 0.5rem;
    display: none;
    position: absolute;
    z-index: 2;
    background-color: #f9f9f9;
    min-width: 300px;
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 1rem;
    height: 250px;
    font-size: 15px;
    padding: 2rem;
    padding-bottom: 1rem;
  }

  .dropdown-content button {
    color: #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border: 2px solid #e0e0e0;
    border-radius: 1rem;
    margin: 1rem auto;
    text-align: center;
    padding: 1rem 2rem;

    width: 95%;
  }

  .dropdown-content button:hover {
    background-color: #f1f1f1;
    outline: 1px solid black;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropdown-button {
    background-color: #f1f1f1;
  }
  .dropdown input {
    margin: 10px;
    font-size: 15px;
    padding: 10px;
    display: block;
    border: 1px solid #79797996;
    border-radius: 5px;
  }
  .input-container-1 {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    box-shadow: none;
    justify-content: space-around;
    outline: none;
    box-sizing: border-box;
  }

  input {
    padding: 10px 30px 10px 10px; /* Increase right padding to make room for icon */
    border: none;
    border-radius: 1rem;
    width: 100%;
    height: 30px;
    font-family: "Mulish";
    font-family: 600;
  }
  a:hover,
  input:hover,
  input:hover {
    outline: 1px solid #000;
  }

  .search-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: #333;
  }

  @media (max-width: 1100px) {
    .nav-container {
      display: none;
    }
  }
`;
const NavbarSecond = ({ parameters }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let state = useSelector((state) => state.explore);
  const [yearSearch, setYearSearch] = useState("");
  const [payloadSearch, setPayloadSearch] = useState("");
  const [cargoSearch, setCargoSearch] = useState("");
  const [makeSearch, setMake] = useState("");
  const [drivenSearch, setDriven] = useState("");
  const [fuelSearch, setFuelSearch] = useState("");

  // const [select, setSelect] = useState({
  //   payload: '',
  //   make:'',

  // });
  const handlePayload = (e) => {
    const min = e.target.dataset.min.split(" ")[0];
    const max = e.target.dataset.max.split(" ")[0];

    const searchParams = { payload_min: min, payload_max: max };
    // searchParams.set("makename", e.target.value);

    navigate({
      pathname: "/second-hand-truck-for-sale",
      search: `?${createSearchParams(searchParams)}`,
    });
  };
  const handleCargo = (e) => {
    const min = e.target.dataset.min.slice(0, 2) - "0";
    const max = e.target.dataset.max.slice(0, 2) - "0";

    const searchParams = { cargo_length_min: min, cargo_length_max: max };

    // searchParams.set("makename", e.target.value);

    navigate({
      pathname: "/second-hand-truck-for-sale",
      search: `?${createSearchParams(searchParams)}`,
    });
  };
  const handleMake = (e) => {
    const searchParams = { makename: e.target.value };
    // searchParams.set("makename", e.target.value);

    navigate({
      pathname: "/second-hand-truck-for-sale",
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  const handleYear = (e) => {
    //  //e.target.value);

    const searchParams = { yearvalues: e.target.value };
    // searchParams.set("makename", e.target.value);

    navigate({
      pathname: "/second-hand-truck-for-sale",
      search: `?${createSearchParams(searchParams)}`,
    });
  };
  const handleFuel = (e) => {
    // //e.target.value);

    const searchParams = { fuelvalues: e.target.value };
    // searchParams.set("makename", e.target.value);

    navigate({
      pathname: "/second-hand-truck-for-sale",
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  const handleKm = (e) => {
    const min = e.target.dataset.min;
    const max = e.target.dataset.max;

    const searchParams = { km_driven_min: min, km_driven_max: max };

    navigate({
      pathname: "/second-hand-truck-for-sale",
      search: `?${createSearchParams(searchParams)}`,
    });
  };

  return (
    <Wrapper>
      <div className="nav-container">
        <div className="nav-inner">
          <h4>Explore By</h4>
          <div className="buttons">
            <div className="dropdown">
              <button className="dropdown-button">
                Payload
                <img src={DropDown} alt="" />
              </button>
              <div className="dropdown-content">
                <div className="input-container-1">
                  <input
                    placeholder="Payload... "
                    value={payloadSearch}
                    onChange={(e) => setPayloadSearch(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="search-icon"
                    style={{ marginRight: "1rem" }}
                  />
                </div>
                {parameters?.payloadValues
                  ?.filter(
                    (payload) =>
                      payload?.details.max_payload
                        .toString()
                        .includes(payloadSearch.toLowerCase()) ||
                      payload?.details.min_payload
                        .toString()
                        .includes(payloadSearch.toLowerCase())
                  )
                  .map((payload) => {
                    // //payload);
                    return (
                      <button
                        key={payload?.details.id}
                        data-max={payload?.details.max_payload}
                        data-min={payload?.details.min_payload}
                        onClick={handlePayload}
                      >
                        {payload?.details.min_payload} -{" "}
                        {payload?.details.max_payload}
                      </button>
                    );
                  })}
                {!parameters.payloadValues && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60%",
                    }}
                  >
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropdown-button">
                Cargo Length
                <img src={DropDown} alt="" />
              </button>
              <div className="dropdown-content">
                <div className="input-container-1">
                  <input
                    placeholder="Custom Length..."
                    value={cargoSearch}
                    onChange={(e) => setCargoSearch(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="search-icon"
                    style={{ marginRight: "1rem" }}
                  />
                </div>
                {parameters?.cargoLengthValues
                  ?.filter(
                    (cargo) =>
                      cargo?.details.min_cargo_length
                        .toString()
                        .includes(cargoSearch.toLowerCase()) ||
                      cargo?.details.max_cargo_length
                        .toString()
                        .includes(cargoSearch.toLowerCase())
                  )
                  .map((cargo) => {
                    // //cargo);
                    return (
                      <button
                        key={cargo?.details.id}
                        data-min={cargo?.details.min_cargo_length}
                        data-max={cargo?.details.max_cargo_length}
                        onClick={handleCargo}
                      >
                        {cargo?.details.min_cargo_length} -{" "}
                        {cargo?.details.max_cargo_length}
                      </button>
                    );
                  })}
                {!parameters.payloadValues && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60%",
                    }}
                  >
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropdown-button">
                Make
                <img src={DropDown} alt="" />
              </button>

              <div className="dropdown-content">
                <div className="input-container-1">
                  <input
                    placeholder="Make..."
                    value={makeSearch}
                    onChange={(e) => setMake(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="search-icon"
                    style={{ marginRight: "1rem" }}
                  />
                </div>
                {parameters?.makeValues
                  ?.filter((make) =>
                    make?.details.name
                      .toLowerCase()
                      .includes(makeSearch.toLowerCase())
                  )
                  .map((make) => {
                    // //"make",make);
                    return (
                      <button
                        key={make?.details.id}
                        value={make?.details.name}
                        onClick={handleMake}
                      >
                        {make?.details.name}
                      </button>
                    );
                  })}
                {!parameters.payloadValues && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60%",
                    }}
                  >
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropdown-button">
                Year
                <img src={DropDown} alt="" />
              </button>

              <div className="dropdown-content">
                <div className="input-container-1">
                  <input
                    placeholder="Manufacture Year..."
                    value={yearSearch}
                    onChange={(e) => setYearSearch(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="search-icon"
                    style={{ marginRight: "1rem" }}
                  />
                </div>
                {parameters?.yearValues
                  ?.filter((year) =>
                    year?.details.year
                      .toString()
                      .includes(yearSearch.toLowerCase())
                  )
                  .map((year) => {
                    // //year);
                    return (
                      <button
                        key={year?.details.id}
                        value={year?.details.year}
                        onClick={handleYear}
                      >
                        {year?.details.year}
                      </button>
                    );
                  })}
                {!parameters.payloadValues && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60%",
                    }}
                  >
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropdown-button">
                Fuel
                <img src={DropDown} alt="" />
              </button>

              <div className="dropdown-content">
                <div className="input-container-1">
                  <input
                    placeholder="Fuel Type..."
                    value={fuelSearch}
                    onChange={(e) => setFuelSearch(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="search-icon"
                    style={{ marginRight: "1rem" }}
                  />
                </div>
                {parameters?.fuelValues
                  ?.filter((fuel) =>
                    fuel?.details.fuel
                      .toLowerCase()
                      .includes(fuelSearch.toLowerCase())
                  )
                  .map((fuel) => {
                    // //fuel);
                    return (
                      <button
                        key={fuel?.details.id}
                        value={fuel?.details.fuel}
                        onClick={handleFuel}
                      >
                        {fuel?.details.fuel}
                      </button>
                    );
                  })}
                {!parameters.payloadValues && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60%",
                    }}
                  >
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="dropdown">
              <button className="dropdown-button">
                Driven
                <img src={DropDown} alt="" />
              </button>

              <div className="dropdown-content">
                <div className="input-container-1">
                  <input
                    placeholder="Driven..."
                    value={drivenSearch}
                    onChange={(e) => setDriven(e.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="search-icon"
                    style={{ marginRight: "1rem" }}
                  />
                </div>

                {parameters?.kmDrivenValues
                  ?.filter(
                    (driven) =>
                      parseInt(
                        driven?.details.min_kms_driven.replace(",", ""),
                        10
                      )
                        .toString()
                        .includes(drivenSearch.toLowerCase()) ||
                      parseInt(
                        driven?.details.max_kms_driven.replace(",", ""),
                        10
                      )
                        .toString()
                        .includes(drivenSearch.toLowerCase())
                  )
                  .map((km) => {
                    // //km);
                    return (
                      <button
                        key={km?.details.id}
                        data-min={km?.details.min_kms_driven}
                        data-max={km?.details.max_kms_driven}
                        onClick={handleKm}
                      >
                        {km?.details.min_kms_driven} -{" "}
                        {km?.details.max_kms_driven}
                      </button>
                    );
                  })}
                {!parameters.payloadValues && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60%",
                    }}
                  >
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NavbarSecond;
