import React, { useState } from 'react'
import { Link } from "react-router-dom";
import logo from "../assets/Logo (1).png";
import { IoMdClose } from 'react-icons/io';
import qs from "qs";
import { toast } from "react-hot-toast";
import { root_url } from "../utils/url.constant";
import axios from "axios";
import "./Header.css";


export default function Header() {

  const [closeBtn, setCloseBtn] = useState(false);
  const [number, setNumber] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [district, setDistrict] = useState("");
  const [name, setName] = useState("");
  const [stateArr, setStateArr] = useState([]);

  const appendSpreadsheet = async () => {
    if (name === "" || number === "" || state === "" || district === "") {
      toast.error("Fields cannot be empty");
      return;
    }

    try {
      let dataObj = qs.stringify({
        name,
        email,
        phone: number,
        state,
        city: district,
        message: window.location.href,
      });

      console.log(dataObj, "dataObjdataObjdataObj");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${root_url}/buy/contact`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: dataObj,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data?.status_code === 400) {
            toast.error(response?.data?.message);
          } else {
            toast.success("We Will Contact You Soon.");
            setCloseBtn(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };


  return (

    <>
      <div className="navbar">
        {/* logo */}
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <button
          className="btn  btn-register"
        >
         Contact Us/संपर्क करें
        </button>

      </div>

      {closeBtn && (
        <div className="main_modal">
          <div className="modal">
            <div className="icon" onClick={() => setCloseBtn(!closeBtn)}>
              <IoMdClose />
            </div>

            <div className="fields">
              <input
                type="text"
                placeholder="Name"
                className="input_box"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Mobile No."
                className="input_box"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />

              <input
                type="email"
                placeholder="Email"
                className="input_box"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

            </div>
            
            <button
              className="btn_submit"
              type="button"
              onClick={appendSpreadsheet}
            >
              Submit
            </button>
          </div>
        </div>
      )}


    </>
  )
}