import React, { useRef, useState } from "react";
import styled from "styled-components";
import "./RegisterPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faLocation,
  faLocationDot,
  faMobile,
  faMobileScreen,
  faSearch,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { get, post } from "../utils/functions";
import Phone from "../assets/Phone.svg";
import Lock from "../assets/Lock.svg";
import Next from "../assets/Next.svg";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import useOnClickOutside from "../utils/useOnClickOutside";

function RegisterPopup(props) {
  const [object, setObject] = useState({
    name: "",
    phone: "",
    otp: "",
    state: 0,
    district: 0,
    email: "",
    isSend: false,
    districts: [],
    states: [],
  });
  const [reg, setReg] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, props.onClose);

  // //"rerender",object.state,object.district)

  const onStateChange = (e) => {
    setObject({ ...object, state: e.target.value });
  };
  const onDistrictChange = (e) => {
    setObject({ ...object, district: e.target.value });
  };

  const onSendOtp = async (event) => {
    event.preventDefault();

    try {
      const resp = await get("/user/sendotp", { mobile_no: object.phone });
      // //resp.data);
      if (resp.status_code === 200) setObject({ ...object, isSend: true });
      else {
        toast.error(resp?.message);
        setObject({ ...object, isSend: false });
      }
      setOtpLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setOtpLoading(false);

      if (error?.response?.data?.message === "Unauthorized") {
        localStorage.clear();

        toast.error("Login to continue");
      } else if (error?.response?.data?.message === "Token expired") {
        localStorage.clear();
        toast.error("Login to continue");
      } else toast.error(error?.response?.data?.message);
    }
  };

  const getStates = async (e) => {
    e.preventDefault();
    // //"event listened");
    try {
      const resp = await get("/general/rto/state", {}, {});
      // //resp);
      if (resp.status_code === 200) setObject({ ...object, states: resp.data });
    } catch (error) {}
  };
  const getDistrict = async (e) => {
    e.preventDefault();
    // //"event listened", object.state);
    try {
      const resp = await get(`/general/rto/code/state/${object.state}`, {}, {});
      // //resp);
      if (resp.status_code === 200)
        setObject({ ...object, districts: resp.data });
      // //object.districts);
    } catch (error) {}
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // //object);
    try {
      const resp = await post(
        "/user/register",
        {
          name: object.name,
          state_id: object.state,
          district_id: object.district,
          email: object.email,
          mobile_no: object.phone,
          otp: object.otp,
        },
        { "Content-Type": "application/json" }
      );
      // //resp);
      // if(resp.status_code===200) alert(resp.status_message)
      if (resp.data !== "") {
        const { token, refresh_token } = resp.data;
        const date = new Date();
        const dateString = date.toISOString();
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("token_date", dateString);
        localStorage.setItem("account", JSON.stringify(resp.data));
      }
      if (resp.status_code === 200) {
        toast.success(resp?.message);

        // setReg(true)
        // toast.success(resp.status_message);
        props.onClose();
      }
      else {
        toast.error(resp?.message);

        // toast.error(resp.status_message);
        // props.onClose();
      }
      setButtonLoading(false);
    } catch (error) {
      //error);
      setButtonLoading(false);
    }
  };
  return (
    <div className="login-popup">
      <div ref={ref} className="container-inner">
        <div>
          <ul class="tab-group">
            <li class="tab1 ">
              <button
                onClick={() => {
                  props.onLogin();
                  props.onClose();
                }}
              >
                Login
              </button>
            </li>
            <li class="tab1 active">
              <button>Register</button>
            </li>
          </ul>
        </div>
        <div className="register-popup-inner">
          <div className="registerBar">
            <h1>Register</h1>
            <button className="close-btn" onClick={props.onClose}>
              <FontAwesomeIcon icon={faXmark} className="cross" />
            </button>
          </div>
          {reg ? (
            <h3>Registeration successfull</h3>
          ) : (
            <form>
              <label>
                <div className="input-container">
                  <FontAwesomeIcon icon={faEnvelope} className="mobile-icon" />
                  <input
                    type="text"
                    value={object.name}
                    onChange={(e) =>
                      setObject({ ...object, name: e.target.value })
                    }
                    placeholder="Name"
                  />
                </div>
              </label>
              <label>
                <div className="input-container">
                  <img src={Phone} className="mobile-icon" />
                  <input
                    type="text"
                    value={object.phone}
                    className="phone-input"
                    onChange={(e) =>
                      setObject({ ...object, phone: e.target.value })
                    }
                    placeholder="Phone No."
                  />
                  {otpLoading ? (
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130" }}
                      size={25}
                    />
                  ) : (
                    <button
                      onClick={(e) => {
                        setOtpLoading(true);
                        onSendOtp(e);
                      }}
                    >
                      GET OTP
                    </button>
                  )}
                </div>
              </label>
              <div className="registerInputContain">
                <label>
                  <div className="input-container">
                    <img src={Lock} className="mobile-icon" />

                    <input
                      type="tel"
                      value={object.otp}
                      onChange={(e) =>
                        setObject({ ...object, otp: e.target.value })
                      }
                      placeholder="OTP"
                    />
                  </div>
                </label>
                <label>
                  <div className="input-container">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="mobile-icon"
                    />
                    <input
                      type="email"
                      value={object.email}
                      onChange={(e) =>
                        setObject({ ...object, email: e.target.value })
                      }
                      placeholder="Email Address"
                    />
                  </div>
                </label>
              </div>
              <label>
                <div className="input-container">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="mobile-icon"
                  />
                  <select
                    value={object.state}
                    onChange={onStateChange}
                    onClick={getStates}
                  >
                    <option value="">Select State</option>
                    {object.states?.map((state) => {
                      return (
                        <option value={state?.id} key={state?.id}>
                          {state?.state}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </label>
              <label>
                <div className="input-container" onClick={getDistrict}>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="mobile-icon"
                  />
                  <select value={object.district} onChange={onDistrictChange}>
                    <option value="">Select District</option>
                    {object.districts.map((district) => {
                      return (
                        <option
                          value={district.id}
                          key={district?.id}
                          style={{ color: "black" }}
                        >
                          {district?.district}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </label>
              <label></label>
              {object.isSend ? (
                <button
                  type="submit"
                  onClick={(e) => {
                    setButtonLoading(true);
                    onSubmit(e);
                  }}
                  disabled={buttonLoading}
                >
                  {buttonLoading ? (
                    <CircularProgress
                      color="inherit"
                      style={{ color: "#1E2130", paddingpRight: "2rem" }}
                      size={25}
                    />
                  ) : (
                    "Create Account  "
                  )}
                  {/* <img src={Next} className="mobile-icon" /> */}
                </button>
              ) : (
                <button
                  type="submit"
                  style={{ opacity: "0.6", cursor: "not-allowed" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Create Account &nbsp; &nbsp;
                  {/* <img src={Next} className="mobile-icon" /> */}
                </button>
              )}
            </form>
          )}
          {/* <button onClick={handleLoginPopupClose}>close</button> */}
        </div>
      </div>
    </div>
  );
}

export default RegisterPopup;
