import React from "react";
import MoneyIcon from "../assets/MoneyTransferIcon.png";
import InTransitIcon from "../assets/InTransitIcon.png";
import ConferenceCallIcon from "../assets/ConferenceCallIcon.png";
import { ServicesItem } from "./index";
const Services = ({ title }) => {
  return (
    <div className="services-section">
      {title}
      <div className="services-inner">
        <div className="services-items" >
          <ServicesItem
            Icon={MoneyIcon}
            title={`Best Price \nIn The Market`}
            description={`Shop with confidence on our platform knowing that you will get the best possible deal available.`}
          />
          <ServicesItem
            Icon={InTransitIcon}
            title={`Faster Buying & Selling`}
            description={`Quick & easy buying & selling process with Efficient & convenient transaction process`}
          />

          <ServicesItem
            Icon={ConferenceCallIcon}
            title={`Professional Dealers`}
            description={`We have verified & approved professional dealers on our platform, so you don’t have to worry on trustability `}
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
