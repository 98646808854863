import React from "react";

import { HappyCustomersItem } from "../components/index";

import Customer1 from "../assets/Customer1.png";
import Customer2 from "../assets/Customer2.png";
import Customer3 from "../assets/Customer3.png";

const HappyCustomers = ({ color,font }) => {
  return (
    <div
      className={`happy-customers-section `}
      style={{ backgroundColor: color }}
    >
      <div className="happy-customers-inner">
        <div className="happy-customers-heading">
          <h3 style={{ color: font }}>
            3000+ <span>Happy Customers</span> <br /> Served By Cavalo
          </h3>
        </div>

        <div className="happy-customers-items">
          <HappyCustomersItem
            CustomerImage={Customer1}
            CustomerReview={
              "I recently purchased a second-hand truck from Cavalo, and I couldn't be happier with my experience. The selection of vehicles & information provided was impressive, and I was able to easily narrow down my search to find the perfect truck for my business needs. Highly recommend!  "
            }
            CustomerName={"Shikar Singh"}
            CustomerCity={"Mumbai"}
          />
          <HappyCustomersItem
            CustomerImage={Customer2}
            CustomerReview={
              "Buying a truck from Cavalo was a breeze. The selection of vehicles was extensive, and I was able to find the perfect truck quickly. The user-friendly interface & secure payment options made the entire process stress-free. I highly recommend this platform to anyone looking for a truck. "
            }
            CustomerName={"Manish Thukral"}
            CustomerCity={"Gujrat"}
          />
          <HappyCustomersItem
            CustomerImage={Customer3}
            CustomerReview={
              "I had a great experience buying a second-hand truck from this website. The website provided lots of helpful information about each truck, and I was able to find one that was exactly what I needed. The buying process was easy and the website helped me arrange the delivery of my new truck. Surely recommended! "
            }
            CustomerName={"Manoj Chauhan"}
            CustomerCity={"Meerut"}
          />
        </div>
      </div>
    </div>
  );
};

export default HappyCustomers;
