import React, { useRef } from "react";

// import styled from "styled-components";
import useOnClickOutside from "../utils/useOnClickOutside";
import TagIcon from "../assets/TagIcon.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  .container {
    position: absolute;
    width: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    left: 0;
    top:120%;
  }
  .inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    width: 100%;
    gap: 20px;
    padding: 2rem 1.5rem 1rem;
    box-sizing: border-box;
  }
  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: #f8b301;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  .heading {
    display: flex;
    gap: 10px;
  }
  .heading h1 {
    font-size: 2rem;
    font-weight: 600;
    color: #1e2130;
  }
  .second {
    font-size: 14px;
    color: #757575;
  }
  .estimateBtn {
    text-decoration: none;
    padding: 0.5rem 1rem;
    background-color: #f8b301;
    color: #1e2130;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 841px) {
    .container{
      left:-100%;
    }
  }
`;

function LoginPopup(props) {
  const ref = useRef(null);
  useOnClickOutside(ref, props.onClose, "btnbtn");

  

  return (
    <Wrapper>
      <div className="container">
        <div ref={ref} className="inner-container">
          <div className="heading">
            <div className="img-container">
              <img src={TagIcon} alt="tag icon" />
            </div>
            <h1>Get estimate</h1>
          </div>
          <div className="second">
            Get best value for your truck in just 5 mins!
          </div>
          <Link className="estimateBtn" to="/estimate">
            Continue
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}

export default LoginPopup;
