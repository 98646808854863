import { configureStore } from "@reduxjs/toolkit";
import landingReducer from "../features/landing/landingSlice";
import adminReducer from "../features/admin/adminSlice";
import exploreReducer from "../features/explore/exploreSlice";
import sellReducer from "../features/sell/sellSlice";
import navReducer from "../features/nav/navSlice";
export default configureStore({
  reducer: {
    nav: navReducer,
    admin: adminReducer,
    landing: landingReducer,
    explore: exploreReducer,
    sell: sellReducer,
  },
});
